<template>
  <div class="menu__switcher d-flex align-items-center">
    <ul class="d-flex">
      <li
          class="h-pointer"
          v-bind:class="[{ active: activeLanguage == language.label }]"
          v-for="(language, index) in languages"
          :key="index"

      >
        <a :href="'/?l='+language.label">{{ language.label }}</a>
      </li>
    </ul>
  </div>
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="79.891" viewBox="0 0 80 79.891">
    <g id="Groupe_16" data-name="Groupe 16" transform="translate(-208.592 -208.922)">
      <path id="Tracé_36" data-name="Tracé 36" d="M264.617,335.567h9.13a63.617,63.617,0,0,1,61.6-61.492v-9.128A72.744,72.744,0,0,0,264.617,335.567Z" transform="translate(-46.755 -46.755)" fill="#fc4c02"/>
      <path id="Tracé_37" data-name="Tracé 37" d="M375.826,428.383h9.27a45.089,45.089,0,0,1,43.058-42.947v-9.27A54.351,54.351,0,0,0,375.826,428.383Z" transform="translate(-139.563 -139.57)" fill="#fedd00"/>
      <path id="Tracé_38" data-name="Tracé 38" d="M208.592,288.812h9.27a72.744,72.744,0,0,1,70.73-70.62v-9.27A82.008,82.008,0,0,0,208.592,288.812Z" transform="translate(0 0)" fill="#0072ce"/>
      <path id="Tracé_39" data-name="Tracé 39" d="M319.793,381.6h9.272a54.351,54.351,0,0,1,52.328-52.218v-9.275A63.618,63.618,0,0,0,319.793,381.6Z" transform="translate(-92.801 -92.791)"/>
      <path id="Tracé_40" data-name="Tracé 40" d="M474.909,432.191a45.089,45.089,0,0,0-43.058,42.947h9.492a35.617,35.617,0,0,1,33.566-33.46Z" transform="translate(-186.317 -186.326)" fill="#009639"/>
    </g>
  </svg>

</template>

<script setup>
import { useStore } from 'vuex';
import {computed} from "vue";

const store = useStore();
const activeLanguage = computed(() => store.getters.activeLanguage);
const languages = [
  {
    language: 'fr',
    label: 'fr',
  },
  {
    language: 'en',
    label: 'en',
  },
];
/*
@click="changeActiveLanguage(language.language)"
const changeActiveLanguage = (language) => {
  store.dispatch('setLanguage', language)
};*/
</script>

<style>

</style>
