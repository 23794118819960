<template>
  <div class="brands">
    <div class="top-title d-flex flex-wrap align-items-center">
      <div class="top-title__left col-12 col-md-10"  >
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 1468 158.115"  v-show="lang == 'fr'" class="--path anim-t-top">
          <g id="Groupe_65" data-name="Groupe 65" transform="translate(530.89 -751.023)">
            <path id="Tracé_156" data-name="Tracé 156" d="M-401.831,795.431v83.824h-39.424V802.272c0-15.148-8.3-25.109-24.275-25.109-16.6,0-25.937,10.795-25.937,25.109v76.982H-530.89V753.722h39.424v21.373c4.977-14.321,20.749-24.072,43.16-24.072C-419.468,751.023-401.831,767-401.831,795.431Z" transform="translate(0)" />
            <path id="Tracé_157" data-name="Tracé 157" d="M-491.372,864.107c-12.66-12.036-18.884-27.8-18.884-47.52s6.225-35.477,18.884-47.513,29.666-18.05,50.626-18.05,37.973,6.015,50.626,18.05,19.095,27.8,19.095,47.513-6.435,35.484-19.095,47.52c-12.653,11.826-29.463,17.847-50.626,17.847C-461.705,881.954-478.718,875.932-491.372,864.107Zm80.922-47.52c0-24.276-12.036-40.251-30.3-40.251-18.057,0-30.086,15.976-30.086,40.251s12.029,40.054,30.086,40.054C-422.485,856.641-410.449,840.869-410.449,816.587Z" transform="translate(114.854)" />
            <path id="Tracé_158" data-name="Tracé 158" d="M-249.521,795.431v83.824h-39.22V802.272c0-15.148-7.676-25.109-21.787-25.109-14.735,0-23.448,10.795-23.448,25.109v76.982h-39.214V802.272c0-15.148-7.676-25.109-21.793-25.109-14.728,0-23.441,10.795-23.441,25.109v76.982h-39.22V753.722h39.22v21.373c4.977-14.118,19.915-24.072,40.048-24.072,20.953,0,35.891,9.127,41.7,26.35,4.36-15.148,20.959-26.35,42.746-26.35C-266.33,751.023-249.521,766.585-249.521,795.431Z" transform="translate(407.705)" />
            <path id="Tracé_159" data-name="Tracé 159" d="M-293.7,753.722h39.43V909.138H-293.7V857.055c-9.541,16.6-23.652,24.9-42.536,24.9-17.223,0-30.7-6.021-40.258-17.847s-14.314-27.8-14.314-47.724,4.774-35.694,14.314-47.513,23.034-17.847,40.258-17.847c18.674,0,32.785,8.293,42.536,24.7Zm0,62.661c0-24.275-11.2-40.048-29.253-40.048-17.433,0-28.425,15.772-28.425,40.048,0,24.7,10.992,40.257,28.425,40.257C-304.9,856.641-293.7,841.079-293.7,816.383Z" transform="translate(779.724)" />
            <path id="Tracé_160" data-name="Tracé 160" d="M-279.084,751.434h39.424V876.967h-39.424V855.594c-4.977,14.321-20.749,24.072-43.16,24.072-28.846,0-46.476-15.975-46.476-44.4V751.434H-329.3v76.982c0,15.141,8.3,25.1,24.275,25.1,16.6,0,25.937-10.788,25.937-25.1Z" transform="translate(902.676 2.288)" />
            <path id="Tracé_161" data-name="Tracé 161" d="M-402.974,751.488V876.516h39.266V786.473a3.668,3.668,0,0,1,3.671-3.67H-326.5V751.488Z" transform="translate(712.01 2.588)" />
            <path id="Tracé_162" data-name="Tracé 162" d="M-229.9,769.023q-18.881-17.946-49.529-17.945c-20.638,0-37.355,5.982-49.956,17.945-12.581,11.977-18.773,27.664-18.773,47.263s6.192,35.294,18.773,47.27c12.6,11.76,29.115,17.742,49.956,17.742,18.162,0,33.429-4.537,45.609-13.828a54.324,54.324,0,0,0,21.183-34.2.348.348,0,0,0-.335-.407h-33.258a6.279,6.279,0,0,0-5.89,4.262c-4.143,11.766-14.439,19.009-27.309,19.009-15.076,0-25.392-10.532-28.491-28.491h54.775v-.026h41.308a.32.32,0,0,0,.335-.3c.19-2.285.394-5.909.394-11.031C-211.106,796.686-217.312,781-229.9,769.023Zm-77.6,33.442c3.506-17.131,13.415-26.62,28.071-26.62,15.483,0,25.792,9.9,29.3,26.62Z" transform="translate(1017.135 0.301)" />
            <path id="Tracé_163" data-name="Tracé 163" d="M-470.275,869.3q-16.8-12.765-16.8-33.619l32.293.7a7.314,7.314,0,0,1,6.77,5.739c2.246,8.477,10.119,12.659,23.606,12.659,16.389,0,24.479-4.57,24.479-13.7,0-6.848-5.187-11.209-14.321-11.209h-33.192c-25.733,0-40.881-13.073-40.881-34.657,0-27.591,23.034-44.19,62.255-44.19,18.254,0,32.785,4.15,43.567,12.659,11,8.5,16.4,19.5,16.4,33.4l-29.351-.69a7.425,7.425,0,0,1-6.842-5.745C-404.666,782.173-412.939,778-427.116,778c-16.389,0-24.479,4.57-24.479,13.691,0,6.848,5.187,11.208,14.314,11.208h33.2c25.726,0,40.882,13.067,40.882,34.657,0,27.6-23.035,44.4-62.255,44.4C-443.919,881.954-458.857,877.8-470.275,869.3Z" transform="translate(236.921)" />
            <path id="Tracé_164" data-name="Tracé 164" d="M-310.639,764.127c11.623,8.51,17.427,20.133,17.427,34.657v80.3h-39.417V863.934c-6.015,10.788-21.793,17.847-43.166,17.847-14.932,0-26.968-3.736-35.891-11.412a36.572,36.572,0,0,1-13.284-28.845c0-11.412,4.36-20.953,13.284-28.635s20.959-11.616,35.891-11.826h43.166v-2.279c0-13.08-11.622-21.793-28.222-21.793-14.124,0-23.6,6.165-26.1,16.186a7.126,7.126,0,0,1-6.822,5.607h-30.362c0-14.524,5.811-26.147,17.427-34.657q17.433-13.069,47.933-13.073C-338.236,751.054-322.044,755.414-310.639,764.127Zm-21.99,69.931v-8.1h-35.694c-11.622,0-18.254,5.188-18.254,14.321,0,9.541,7.879,14.321,23.441,14.321Q-332.622,854.6-332.629,834.058Z" transform="translate(589.576 0.172)" />
            <path id="Tracé_165" data-name="Tracé 165" d="M-308.327,869.3q-16.8-12.765-16.8-33.619l32.293.7a7.313,7.313,0,0,1,6.77,5.739c2.252,8.477,10.119,12.659,23.606,12.659,16.389,0,24.479-4.57,24.479-13.7,0-6.848-5.187-11.209-14.315-11.209h-33.2c-25.733,0-40.881-13.073-40.881-34.657,0-27.591,23.034-44.19,62.254-44.19,18.254,0,32.785,4.15,43.574,12.659,10.992,8.5,16.4,19.5,16.4,33.4l-29.357-.69a7.432,7.432,0,0,1-6.842-5.745C-242.718,782.173-250.984,778-265.161,778c-16.389,0-24.485,4.57-24.485,13.691,0,6.848,5.187,11.208,14.314,11.208h33.205c25.72,0,40.875,13.067,40.875,34.657,0,27.6-23.034,44.4-62.248,44.4C-281.971,881.954-296.909,877.8-308.327,869.3Z" transform="translate(1138.362)" />
          </g>
        </svg>
        <svg id="Groupe_96" data-name="Groupe 96" xmlns="http://www.w3.org/2000/svg"  v-show="lang == 'en'" class="--path anim-t-top" viewBox="0 0 1468 193.904">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_56" data-name="Rectangle 56" width="1468" height="193.904" fill="#0072ce"/>
            </clipPath>
          </defs>
          <g id="Groupe_96-2" data-name="Groupe 96" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Tracé_239" data-name="Tracé 239" d="M23.159,142.82C7.634,128.06,0,108.726,0,84.544S7.634,41.036,23.159,26.276,59.54,4.14,85.252,4.14c25.7,0,46.559,7.376,62.085,22.136s23.409,34.094,23.409,58.268-7.891,43.516-23.409,58.276c-15.525,14.5-36.14,21.887-62.085,21.887-25.712,0-46.568-7.384-62.093-21.887M122.4,84.544c0-29.77-14.76-49.362-37.146-49.362-22.144,0-36.9,19.592-36.9,49.362s14.752,49.12,36.9,49.12c22.386,0,37.146-19.342,37.146-49.12" transform="translate(0 29.197)" fill="#0072ce"/>
            <path id="Tracé_240" data-name="Tracé 240" d="M132.108,4.551h48.347V158.5H132.108V132.288c-6.112,17.562-25.446,29.52-52.929,29.52-35.375,0-57-19.592-57-54.451V4.551H70.531V98.958c0,18.569,10.178,30.785,29.77,30.785,20.357,0,31.807-13.23,31.807-30.785Z" transform="translate(156.452 32.095)" fill="#0072ce"/>
            <path id="Tracé_241" data-name="Tracé 241" d="M214.926,55.216c11.7,14.5,17.554,34.1,17.554,58.525s-5.854,44.031-17.554,58.533S186.678,193.9,165.556,193.9c-22.9,0-40.206-10.186-52.164-30.285v26.976H65.045V0h48.347V63.872c11.708-20.365,29-30.535,52.164-30.535,21.122,0,37.661,7.376,49.37,21.879m-30.793,58.525c0-30.277-13.488-49.362-34.859-49.362-22.136,0-35.882,19.084-35.882,49.362,0,29.778,13.746,49.12,35.882,49.12,21.371,0,34.859-19.342,34.859-49.12" transform="translate(458.729 -0.001)" fill="#0072ce"/>
            <path id="Tracé_242" data-name="Tracé 242" d="M279.052,58.6V161.4H230.7V67c0-18.585-10.178-30.793-29.77-30.793-20.357,0-31.807,13.23-31.807,30.793V161.4H120.78V7.449h48.347V33.659c6.1-17.554,25.446-29.52,52.929-29.52,35.367,0,57,19.6,57,54.459" transform="translate(851.799 29.189)" fill="#0072ce"/>
            <path id="Tracé_243" data-name="Tracé 243" d="M260.5,0h48.355V190.594H260.5V163.618c-11.95,20.1-29.263,30.285-52.164,30.285-21.122,0-37.654-7.126-49.362-21.629s-17.562-34.1-17.562-58.533,5.854-44.023,17.562-58.525,28.24-21.879,49.362-21.879c23.159,0,40.464,10.17,52.164,30.535Zm0,113.741c0-30.277-13.738-49.362-35.874-49.362-21.371,0-34.859,19.084-34.859,49.362,0,29.778,13.488,49.12,34.859,49.12,22.136,0,35.874-19.342,35.874-49.12" transform="translate(997.32 -0.001)" fill="#0072ce"/>
            <path id="Tracé_244" data-name="Tracé 244" d="M43.1,4.566V158.393H91.4V47.607a4.521,4.521,0,0,1,4.517-4.517h41.253V4.566Z" transform="translate(303.983 32.201)" fill="#0072ce"/>
            <path id="Tracé_245" data-name="Tracé 245" d="M86.786,4.566V158.393h48.307V47.607a4.516,4.516,0,0,1,4.517-4.517h41.253V4.566Z" transform="translate(612.057 32.201)" fill="#0072ce"/>
            <path id="Tracé_246" data-name="Tracé 246" d="M239.635,20.211C253.88,30.639,261.006,44.891,261.006,62.7v98.474H212.659V142.608c-7.376,13.222-26.718,21.887-52.929,21.887-18.319,0-33.079-4.59-44.023-14s-16.29-21.371-16.29-35.366,5.355-25.7,16.29-35.125,25.7-14.245,44.023-14.494h52.929V62.7c0-16.032-14.253-26.718-34.61-26.718-19.423,0-31.67,9.5-32.741,24.528a2.285,2.285,0,0,1-2.247,2.19H101.648a1.211,1.211,0,0,1-1.192-1.224c.322-17.24,7.441-31.075,21.363-41.269Q143.186,4.17,180.6,4.17c25.188,0,45.038,5.347,59.033,16.041m-26.976,85.751V96.041H168.886c-14.245,0-22.386,6.361-22.386,17.554,0,11.7,9.663,17.562,28.747,17.562q37.42,0,37.412-25.2" transform="translate(701.137 29.408)" fill="#0072ce"/>
            <path id="Tracé_247" data-name="Tracé 247" d="M185.384,149.188q-20.606-15.654-20.614-41.221l47.083,1.015c0,15.01,9.929,22.394,29.77,22.394,20.1,0,30.028-5.6,30.028-16.8,0-8.4-6.362-13.746-17.563-13.746H213.383c-31.558,0-50.135-16.024-50.135-42.493,0-33.837,28.248-54.2,76.337-54.2,22.394,0,40.214,5.089,53.436,15.525C306.51,30.1,313.129,43.588,313.129,60.635l-43.516-1.023c0-15.01-10.436-22.386-31.3-22.386-20.1,0-30.02,5.6-30.02,16.789,0,8.4,6.362,13.746,17.546,13.746h40.721c31.55,0,50.135,16.024,50.135,42.493,0,33.845-28.248,54.459-76.346,54.459-22.644,0-40.971-5.1-54.966-15.525" transform="translate(1151.304 29.189)" fill="#0072ce"/>
          </g>
        </svg>
      </div>

      <div class="top-title__right col-12 col-md-1">
        <p v-html="content.intro" class="--color"></p>
      </div>

    </div>
    <div class="brands__list d-flex ">
      <ul class="d-flex flex-wrap align-items-end justify-content-center w-100 anim-children-opacity">
        <li>
          <svg id="Composant_6_12" data-name="Composant 6 – 12" xmlns="http://www.w3.org/2000/svg" width="160.254" height="19.572" viewBox="0 0 160.254 19.572" class="--path">
            <path id="Tracé_58" data-name="Tracé 58" d="M1929.952,8397.045l-4.672,5.607h8.41Zm-84.1-.469c-5.99.74-9.666,1.79-9.344,6.076.617,8.209,23.707,7.214,23.828-.468C1860.435,8395.966,1851.436,8395.894,1845.853,8396.576Zm24.3,8.411c-4.471,8.892-43.96,10.821-44.385-1.869-.434-13.054,40.545-13.986,43.918-2.8,4.822-7.135,26.561-8.956,38.671-6.543l.108,2.8s-27.636-2.545-27.566,6.542c.024,2.981,4.135,4.836,8.409,5.14a39.867,39.867,0,0,0,9.083-.446l.233-4.533-6.513-.048v-2.173l14.988.057-.037,7.61,17.376-15.921h12.619l13.923,18.725h-11.213l-3.9-5.376h-12.625l-6.368,5.376-18.338-.01C1886.272,8411.341,1874.76,8412.57,1870.148,8404.987Z" transform="translate(-1790.722 -8392.574)" />
            <path id="Tracé_59" data-name="Tracé 59" d="M1749.8,8411.8l3.271-18.687,11.316-.038-1.037,7.514,14.021-7.514h13.241l-15.114,8.447,14.259,10.3-14.259-.02-12.148-9.345-1.4,9.345H1749.8" transform="translate(-1749.799 -8392.845)" />
          </svg>
        </li>
        <li>
          <svg id="Groupe_25" data-name="Groupe 25" xmlns="http://www.w3.org/2000/svg" width="173.967" height="66.826" viewBox="0 0 173.967 66.826" class="--path">
            <path id="Tracé_60" data-name="Tracé 60" d="M2426.356,8305.032l-.086.038c-1.051.475-2.051.8-3.531,1.37a18.3,18.3,0,0,0-6.231,3.938,18.634,18.634,0,0,0-1.365,1.709l-.075.105c-4.12,5.89-5.212,14.188-5.516,19.137l-.022.59c-.015.936-.046,1.557-.046,1.681,0-.453.022-1.026.046-1.681.08-4.444.01-15.907-6-15.643,1.716-.181,4.039-.472,4.085-1.27.044-.767-2.443-1.572-4.744-1.572-.264,0-1.114-.026-1.365-.012,2.216-.145,5.393-.549,5.387-1.277s-2.171-1.747-4.913-1.914a19.949,19.949,0,0,0-2.187-.031c.342-.021,1.112-.043,1.516-.043.216,0,.35-.027.719-.035,2.618-.187,4.78-.771,4.956-1.773.327-1.872-9.2-2.247-10.639-2.428.633,0,7.442-.019,7.7-.019,10.4,0,18.831-2.851,18.831-6.484s-13.892-9.776-33.277-6.124c4.754-3.021,17.78-3.243,19.381-3.146,12.995.792,22.857,4.64,22.857,9.271,0,2.095-2.019,4.028-5.435,5.592l-.043.021Z" transform="translate(-2321.012 -8290.132)" />
            <path id="Tracé_61" data-name="Tracé 61" d="M2404.046,8414.947c-2.028,0-6.347.226-6.347-2.984,0-5.973,3.706-6.721,8.215-6.721,1.416,0,3.734,1.291,3.734,2.611,0,3.953-1.351,7.094-5.6,7.094Zm-79.806-9.319c1.611,0,9.608-.537,9.608,1.106,0,1.428-1.106,2.24-2.614,2.24l-7.453,0,.458-3.35Zm68.6,4.838v2.244c0,3.838,5.03,4.854,8.961,4.854,7.331,0,13.069-2.066,13.069-9.336,0-5.208-6.7-5.6-11.2-5.6-4.916,0-10.829,2.922-10.829,7.839Zm-59.37.749c6.194-.515,6.858-8.215,0-8.215h-13.442l-2.531,14.205,4.771-.017.885-5.576,4.716-.022c1.056,0,3.818,4.517,4.108,5.6h5.227l-3.734-5.974Zm32.838-8.217-4.833,0-1.653,10.054c.048,2.465,2.994,4.135,5.386,4.135h8.961l.374-2.241c-1.928,0-3.752.159-5.35.114-3.886-.114-4.935-1.137-4.289-4.586l1.4-7.478Zm-60.2,2.908,2.31,5.324-6.367-.061,4.057-5.264Zm-8.483,11.282,2.988-3.36h8.961l1.174,3.341,5.173.02L2308.459,8403H2305.1l-12.322,14.188Zm-49.982-11.567,6.294-.01c2.834,0,2.988.717,2.988,2.242,0,1.162-1.848,1.865-2.988,1.865l-6.806-.008.511-4.09Zm6.668,6.715c3.388,0,7.84-1.587,7.84-4.854v-.747c0-3.682-5.166-3.734-8.96-3.734h-9.708l-2.581,14.2,4.821-.007.768-4.849,7.82,0Zm39.142-9.35-4.79.014-1.558,9.7-.682.014-7.094-9.71h-5.227l-2.691,14.177,4.93.012,1.614-10.433,8.094,10.433,4.694-.031,2.71-14.171Zm-22.717.014h-4.85l-2.675,14.178,4.9.008,2.625-14.186Zm74.682,5.975c.522-5.3,11.628-3.775,13.133-3.905l.31-2.069h-8.588c-6.171,0-10.829,3.222-10.829,9.336,0,4.945,7.3,4.853,10.731,4.853h6.445l.406-2.606-9.741-.008c-2.148,0-2.614-1.168-2.614-3.359H2357.3l.443-2.24Zm37.556-5.977-4.833,0-1.652,10.054c.048,2.465,2.993,4.135,5.385,4.135h8.961l.374-2.241c-1.928,0-3.752.159-5.349.114-3.887-.114-4.936-1.137-4.29-4.586l1.4-7.478Z" transform="translate(-2240.908 -8350.737)"  fill-rule="evenodd"/>
          </svg>
        </li>
        <li>
          <svg id="Composant_9_12" data-name="Composant 9 – 12" xmlns="http://www.w3.org/2000/svg" width="62.482" height="34.335" viewBox="0 0 62.482 34.335" class="--path">
            <g id="Primer_plano" data-name="Primer plano">
              <g id="Groupe_26" data-name="Groupe 26" transform="translate(0 0)">
                <path id="Tracé_62" data-name="Tracé 62" d="M2762.788,8444.91h54.558l-1.112,4.658h-54.489Z" transform="translate(-2761.746 -8415.233)" />
                <path id="Tracé_63" data-name="Tracé 63" d="M2777.943,8395.377h4.24c1.32,0,3.891.137,3.266,2.292-.7,2.5-3.337,2.5-4.31,2.5h-4.309Zm2.084-8.9h3.823c1.39,0,3.335.208,2.779,2.086-.417,1.6-2.085,2.222-3.613,2.222h-4.031Zm-7.784-5.907h13.97c14.8,0,8.479,11.118,4.587,11.885,0,0-.278.138-.07.209,5.768.764,5.63,13.414-9.8,13.414h-14.665Zm45.454.21h8.966l-5.838,24.881h-8.966l2.223-9.594h-8.548l-2.224,9.594h-8.966l5.837-24.881h8.966l-1.946,8.408h8.619Z" transform="translate(-2764.181 -8380.572)"  fill-rule="evenodd"/>
              </g>
            </g>
          </svg>
        </li>
        <li>
          <svg id="Groupe_27" data-name="Groupe 27" xmlns="http://www.w3.org/2000/svg" width="156.338" height="20.274" viewBox="0 0 156.338 20.274" class="--path">
            <path id="Tracé_64" data-name="Tracé 64" d="M3058.2,8397.737c.191-1.54.08-1.674-1.344-1.678-2.131,0-4.263-.01-6.394,0a1.953,1.953,0,0,0-1.907,2.634c.1.259.679.465,1.04.47,2.779.037,5.559,0,8.339.036a19.223,19.223,0,0,1,3.4.313,1.77,1.77,0,0,1,1.594,2.21c-.376,2.4-.791,4.807-1.334,7.177-.388,1.688-1.459,2.448-3.563,2.477-4.879.075-9.758.033-14.638.013a5.232,5.232,0,0,1-1.441-.3,1.334,1.334,0,0,1-1.06-1.446c.114-1.285.28-2.569.513-3.835a1.02,1.02,0,0,1,.771-.58c1.417-.061,2.841-.057,4.259,0,.188,0,.547.445.511.616-.158.768.191,1.076.861,1.079,2.409.017,4.819.034,7.227-.014,1.1-.024,2.186-2.006,1.6-2.906a1.434,1.434,0,0,0-1.049-.37c-3.057-.029-6.116.006-9.173-.029a13.142,13.142,0,0,1-2.658-.3,1.7,1.7,0,0,1-1.48-2.1c.371-2.312.763-4.623,1.274-6.905.4-1.806,1.512-2.655,3.609-2.683q7.319-.1,14.638,0c1.985.021,2.595.752,2.481,2.713a21.778,21.778,0,0,1-.456,2.919c-.036.188-.3.466-.461.47C3061.647,8397.75,3059.934,8397.737,3058.2,8397.737Z" transform="translate(-3040.888 -8391.329)" />
            <path id="Tracé_65" data-name="Tracé 65" d="M3253.1,8403.545c1.282,2.637,2.485,5.113,3.794,7.809-2.009,0-3.751.03-5.488-.037-.241-.01-.532-.47-.685-.777q-1.558-3.154-3.055-6.333a1.034,1.034,0,0,0-1.122-.708c-1.327.056-2.657.067-3.981.007-.768-.04-.994.28-1.106.972-.33,2.006-.744,4-1.077,6-.114.683-.409.938-1.1.911-1.2-.047-2.408-.009-3.612-.012s-1.261-.065-1.042-1.274q.734-4.046,1.494-8.089c.6-3.236,1.234-6.472,1.806-9.715.14-.791.389-1.136,1.285-1.127,5.155.049,10.312.017,15.467.034a8.072,8.072,0,0,1,1.831.209,2.552,2.552,0,0,1,2.08,3.05,36.85,36.85,0,0,1-1.165,5.779A4.266,4.266,0,0,1,3253.1,8403.545Zm-10.584-4.5a2.358,2.358,0,0,0,.437.094c2.559,0,5.117.01,7.677,0a1.842,1.842,0,0,0,1.73-2.569,1.405,1.405,0,0,0-1.071-.723c-2.558-.065-5.118-.043-7.676-.014a.746.746,0,0,0-.589.392C3242.809,8397.149,3242.677,8398.091,3242.512,8399.049Z" transform="translate(-3145.203 -8391.115)" />
            <path id="Tracé_66" data-name="Tracé 66" d="M3211.277,8391.526c-.28,1.483-.486,2.788-.807,4.067-.053.209-.6.42-.926.421-4.11.03-8.221.052-12.33,0-.888-.012-1.2.289-1.255,1.119a18.784,18.784,0,0,1-.365,2.037h1.059q4.914,0,9.827,0c1.035,0,1.139.123.964,1.119-.15.85-.371,1.69-.469,2.547-.075.657-.4.785-.984.781-3.461-.019-6.922,0-10.383-.019-.578,0-.907.087-.969.765-.074.817-.285,1.619-.461,2.561h14.231c-.268,1.462-.491,2.783-.776,4.094-.037.172-.4.385-.607.385q-9.5.026-19,0a1.826,1.826,0,0,1-.384-.117c.2-1.158.4-2.33.613-3.492q1.374-7.462,2.759-14.918c.249-1.348.244-1.348,1.641-1.348h18.627Z" transform="translate(-3119.947 -8391.309)" />
            <path id="Tracé_67" data-name="Tracé 67" d="M3347.834,8401.108a3.18,3.18,0,0,1,.687,3.05,37.77,37.77,0,0,1-1.115,3.8,5.037,5.037,0,0,1-4.979,3.338c-5.249.051-10.5.02-15.75.011-.926,0-1.069-.259-.575-1.038.628-.99,1.323-1.941,1.923-2.944a1.2,1.2,0,0,1,1.19-.651c3.984.022,7.969.016,11.952.013a2.179,2.179,0,0,0,2.13-2.581c-.059-.249-.634-.525-.983-.54-1.387-.062-2.779-.028-4.168-.028-2.162,0-4.323.01-6.486,0-.824,0-.942-.223-.548-.937.03-.052.066-.1.1-.154.753-1.128,1.27-2.672,2.325-3.267s2.626-.24,3.972-.26c1.853-.022,3.706,0,5.559-.01a2.113,2.113,0,0,0,2.055-2.545.781.781,0,0,0-.457-.477,3.567,3.567,0,0,0-1-.105q-5.929-.007-11.86-.008c-.39,0-.779-.043-1.341-.077a7.093,7.093,0,0,1,.43-1.011c.73-1.143,1.249-2.685,2.3-3.283s2.624-.261,3.971-.268c3.335-.024,6.67-.018,10.006,0a6.765,6.765,0,0,1,1.644.214,2.339,2.339,0,0,1,1.913,2.406C3350.626,8396.5,3350.024,8399.073,3347.834,8401.108Z" transform="translate(-3194.392 -8391.054)" />
            <path id="Tracé_68" data-name="Tracé 68" d="M3099.257,8391.518c-.238,1.355-.439,2.562-.661,3.76-.586,3.152-1.183,6.3-1.77,9.456a6.484,6.484,0,0,0-.131,1.009c-.017.558.2,1.016.821,1.021,2.464.021,4.931.033,7.4-.014.877-.017,1.142-.769,1.281-1.48.4-2.062.763-4.125,1.145-6.187.421-2.272.835-4.551,1.288-6.817.053-.267.333-.692.52-.7,1.719-.055,3.441-.027,5.3-.027-.245,1.393-.459,2.658-.692,3.922-.711,3.848-1.445,7.693-2.141,11.547-.508,2.813-2.154,4.338-4.985,4.374q-6.116.083-12.233.015c-3.174-.035-4.264-1.366-3.7-4.467.878-4.852,1.822-9.693,2.7-14.547.116-.646.347-.893,1.014-.873C3095.969,8391.545,3097.536,8391.518,3099.257,8391.518Z" transform="translate(-3067.641 -8391.299)" />
            <path id="Tracé_69" data-name="Tracé 69" d="M3146.019,8403.576c-.447,2.379-.909,4.67-1.291,6.978-.112.672-.379.873-1.022.857-1.567-.029-3.135-.009-4.851-.009.307-1.716.58-3.283.868-4.845.854-4.64,1.732-9.273,2.554-13.919.152-.849.451-1.166,1.366-1.156,4.943.045,9.885.022,14.828.027a8.59,8.59,0,0,1,1.655.135,2.71,2.71,0,0,1,2.448,3.39c-.215,1.619-.5,3.233-.858,4.824-.564,2.5-2.221,3.7-5.027,3.712C3153.17,8403.588,3149.651,8403.576,3146.019,8403.576Zm.84-4.43c2.8,0,5.454.028,8.1-.016,1.05-.017,1.568-.816,1.55-2.087-.015-1.043-.836-.987-1.461-1-2.345-.055-4.692-.037-7.038-.006a.833.833,0,0,0-.66.431C3147.14,8397.325,3147.022,8398.209,3146.859,8399.146Z" transform="translate(-3093.667 -8391.284)" />
            <path id="Tracé_70" data-name="Tracé 70" d="M3301.488,8395.842h-3.331c-2.533,0-5.067.007-7.6,0-.93,0-1.076-.241-.6-1.027.629-1.026,1.266-2.049,1.976-3.021a1.485,1.485,0,0,1,1.034-.547c5.87-.037,11.741-.029,17.611-.028.955,0,1.093.229.586,1.014q-6.008,9.3-12.046,18.572a1.207,1.207,0,0,1-.78.556c-2.155.044-4.312.024-6.8.024C3294.9,8406.139,3298.127,8401.1,3301.488,8395.842Z" transform="translate(-3174.928 -8391.145)" />
          </svg>

        </li>
        <li>
          <svg id="Groupe_29" data-name="Groupe 29" xmlns="http://www.w3.org/2000/svg" width="151.211" height="45.579" viewBox="0 0 151.211 45.579" class="--path">
            <path id="Tracé_71" data-name="Tracé 71" d="M3775.294,8365.19a22.79,22.79,0,1,0,22.79,22.788A22.814,22.814,0,0,0,3775.294,8365.19Zm0,42.37a19.446,19.446,0,0,1-13.8-5.692l0,0c-.266-.27-.529-.544-.782-.825l1.192-1.073c.249.268.5.523.761.779a18.193,18.193,0,0,0,22.452,2.021h2.977A19.564,19.564,0,0,1,3775.294,8407.561Zm16.079-12.845a8.017,8.017,0,0,0-1.306-2l3.35-7.743a79.129,79.129,0,0,0-5.5,5.875,16.617,16.617,0,0,0-16.342-.729l-.215-.349c2.946-1.964,7.828-3.366,10.338-3.047l1.52-1.806c-6.79-.492-11.659,2.729-25.519,15.741l.174-3.134c.079-.06.151-.11.229-.168a19.578,19.578,0,0,1,17.188-28.958,20.805,20.805,0,0,1,3.963.4.114.114,0,0,1,.1.149l-.054.24a.13.13,0,0,1-.15.1,19.426,19.426,0,0,0-3.962-.41,18.445,18.445,0,0,0-18.25,18.6,18.033,18.033,0,0,0,2.374,9c6.494-4.775,9.19-7.464,10.76-10.636a4.583,4.583,0,0,0-.39-4.458,37.988,37.988,0,0,0,7.86.938,26.207,26.207,0,0,0,6.192-.432,16.276,16.276,0,0,0-2.115-5.326l-.961-.192-1.3.366a14.891,14.891,0,0,0-2.225-.894c-.242-.075-.284-.214.106-.343.369-.124,1.526-.5,1.526-.5a31.246,31.246,0,0,1-7.224-3.085c-.212-.117-.132-.25.094-.174.281.1.51.188,1.047.359,3.774,1.234,6.758,2.162,15.127,3.006l.157.85a10.7,10.7,0,0,1-3.183,1.061,23.585,23.585,0,0,1,4.435,8.926,24.724,24.724,0,0,1,4.17-2.775,1.313,1.313,0,0,1,1.763,1.224C3794.547,8386.352,3792.258,8392.5,3791.373,8394.716Z" transform="translate(-3646.873 -8365.19)"  fill-rule="evenodd"/>
            <g id="Groupe_28" data-name="Groupe 28" transform="translate(0 14.245)">
              <path id="Tracé_72" data-name="Tracé 72" d="M3640.588,8405.19l-2.939.017c-3.838,0-5.785,1.773-5.785,4.9v3.2c0,3.137,1.948,4.746,5.785,4.746h7.608v-2.641h-6.186c-2.347,0-3.366-.334-3.366-1.943v-.395l5.454-.047c3.581,0,5.031-1.492,5.031-3.826,0-2.936-1.688-4.009-5.6-4.009Zm-.273,5.331-4.628.02v-1.347l.005-.01c0-1.046.664-1.257,2.029-1.257h2.339c1.57,0,2.293.08,2.293,1.206,0,1.056-.673,1.388-2.038,1.388Z" transform="translate(-3581.881 -8400.984)"  fill-rule="evenodd"/>
              <path id="Tracé_73" data-name="Tracé 73" d="M3707.814,8405.19l-2.927.017c-3.841,0-5.785,1.773-5.785,4.9v3.2c0,3.137,1.944,4.746,5.785,4.746h7.6v-2.641H3706.3c-2.352,0-3.363-.334-3.363-1.943v-.395l5.445-.047c3.587,0,5.031-1.492,5.031-3.826,0-2.936-1.684-4.009-5.6-4.009Zm-.27,5.331-4.631.02v-1.347l.008-.01c.009-1.046.674-1.257,2.025-1.257h2.333c1.581,0,2.3.08,2.3,1.206,0,1.056-.663,1.388-2.033,1.388Z" transform="translate(-3618.105 -8400.984)"  fill-rule="evenodd"/>
              <path id="Tracé_74" data-name="Tracé 74" d="M3672.142,8413.162h-3.788V8396.07h3.788v17.092Z" transform="translate(-3601.54 -8396.07)"  fill-rule="evenodd"/>
              <path id="Tracé_75" data-name="Tracé 75" d="M3539.208,8411.427v-2.489h-6.688v2.521h2.837c0,.241.009,1.343.009,1.436,0,1.057-.664,1.188-2.03,1.188h-2.63c-2.353,0-3.321-.446-3.321-2.055l-.009-5.7c.009-1.049.619-1.187,1.98-1.187h8.909v-2.62h-8.978c-3.841,0-5.785,1.614-5.785,4.743v4.625c0,3.124,1.944,4.735,5.785,4.735l5.562-.009c2.819,0,4.358-1.361,4.358-3.7v-1.492Z" transform="translate(-3523.503 -8399.548)"  fill-rule="evenodd"/>
              <path id="Tracé_76" data-name="Tracé 76" d="M3598.76,8408.249h8.231l-5.988,5.167c-.74.642-2.243,1.951-2.243,2.509v2.377h13.173v-2.559h-8.5s7.518-6.471,7.815-6.721a1.617,1.617,0,0,0,.687-1.064v-2.321H3598.76v2.612Z" transform="translate(-3564.047 -8401.226)"  fill-rule="evenodd"/>
              <path id="Tracé_77" data-name="Tracé 77" d="M3571.434,8405.637h-7.6v2.622h6.195c2.333,0,3.351.429,3.351,2.035v.321H3568.5c-3.923,0-5.6,1.1-5.6,4.04,0,2.334,1.549,3.661,4.346,3.661h9.972v-7.948c0-3.129-1.947-4.731-5.786-4.731Zm1.971,10.121h-4.64c-1.365,0-2.025-.147-2.025-1.2,0-1.124.719-1.417,2.289-1.417h4.376v2.621Z" transform="translate(-3544.729 -8401.226)"  fill-rule="evenodd"/>
              <path id="Tracé_78" data-name="Tracé 78" d="M3688.139,8413.162h-3.8V8396.07h3.8v17.092Z" transform="translate(-3610.152 -8396.07)"  fill-rule="evenodd"/>
            </g>
          </svg>
        </li>
      </ul>
    </div>
    <div class="brands__bottom d-flex justify-content-center">
      <svg xmlns="http://www.w3.org/2000/svg" width="47.5" height="47.5" viewBox="0 0 47.5 47.5" class="--path">
        <path id="Tracé_166" data-name="Tracé 166" d="M2341.1,2403.815H2322.92a5.016,5.016,0,0,1-5.01-5.012v-18.083c-.1,0-.2,0-.308,0s-.232,0-.348,0v18.084a5.012,5.012,0,0,1-5,5.01h-18.15c0,.134-.005.267-.005.4,0,.085,0,.169,0,.253h18.154a5.009,5.009,0,0,1,5,5.011v18.231c.116,0,.232,0,.348,0s.205,0,.308,0v-18.232a5.01,5.01,0,0,1,5-5.011H2341.1c0-.085,0-.168,0-.253C2341.1,2404.083,2341.1,2403.949,2341.1,2403.815Z" transform="translate(-2293.852 -2380.467)"  stroke-miterlimit="10" stroke-width="0.5"/>
      </svg>

    </div>
  </div>
</template>


<script>


export default {
  name:'Services',
  data: function() {
    return {

    }
  },
  props:{
    content:String,
    lang : String
  },

  methods: {

  }
};
</script>

