<template>
  <div class="the-store">
    <div class="big_title ">
      <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 1468 226.571" class="--path anim-t-top "  v-show="lang == 'fr'" >
        <g id="Groupe_45" data-name="Groupe 45" transform="translate(470.482 -1129.307)">
          <path id="Tracé_109" data-name="Tracé 109" d="M-470.482,1321.773V1129.307h48.822v192.467Z" transform="translate(0 0)" />
          <path id="Tracé_110" data-name="Tracé 110" d="M-176.22,1188.441v103.808h-48.57v-95.326c0-18.769-9.506-31.1-26.973-31.1-18.247,0-29.038,13.361-29.038,31.1v95.326h-48.57v-95.326c0-18.769-9.506-31.1-26.981-31.1-18.239,0-29.038,13.361-29.038,31.1v95.326h-48.562v-155.46h48.562v26.468c6.164-17.474,24.671-29.81,49.595-29.81,25.948,0,44.455,11.311,51.652,32.641,5.4-18.769,25.956-32.641,52.929-32.641C-197.037,1133.447-176.22,1152.718-176.22,1188.441Z" transform="translate(260.518 29.525)" />
          <path id="Tracé_111" data-name="Tracé 111" d="M-259.532,1149.676c14.385,10.531,21.581,24.923,21.581,42.91v99.442h-48.822v-18.751c-7.448,13.352-26.981,22.1-53.449,22.1-18.491,0-33.4-4.635-44.455-14.14-11.043-9.506-16.442-21.582-16.442-35.715s5.4-25.956,16.442-35.469c11.059-9.507,25.964-14.385,44.455-14.637h53.449v-2.83c0-16.189-14.384-26.98-34.949-26.98-17.491,0-29.217,7.627-32.323,20.045a8.817,8.817,0,0,1-8.441,6.936h-37.6c0-17.986,7.188-32.379,21.581-42.91q21.59-16.2,59.361-16.2C-293.71,1133.478-273.657,1138.877-259.532,1149.676Zm-27.241,86.593v-10.019h-44.2c-14.393,0-22.614,6.424-22.614,17.728,0,11.815,9.766,17.735,29.038,17.735Q-286.769,1261.714-286.773,1236.27Z" transform="translate(494.663 29.746)" />
          <path id="Tracé_112" data-name="Tracé 112" d="M-217,1149.676c14.393,10.531,21.581,24.923,21.581,42.91v99.442h-48.822v-18.751c-7.448,13.352-26.981,22.1-53.449,22.1-18.491,0-33.4-4.635-44.456-14.14-11.043-9.506-16.442-21.582-16.442-35.715s5.4-25.956,16.442-35.469c11.059-9.507,25.964-14.385,44.456-14.637h53.449v-2.83c0-16.189-14.385-26.98-34.941-26.98-17.491,0-29.225,7.627-32.323,20.045a8.825,8.825,0,0,1-8.449,6.936h-37.6c0-17.986,7.2-32.379,21.581-42.91q21.59-16.2,59.361-16.2C-251.169,1133.478-231.125,1138.877-217,1149.676Zm-27.241,86.593v-10.019h-44.2c-14.393,0-22.606,6.424-22.606,17.728,0,11.815,9.758,17.735,29.03,17.735Q-244.225,1261.714-244.241,1236.27Z" transform="translate(797.985 29.746)" />
          <path id="Tracé_113" data-name="Tracé 113" d="M-314.967,1279.921q-20.809-15.808-20.817-41.626l39.991.863c4.123.09,7.327,3.115,8.384,7.1,2.789,10.5,12.531,15.679,29.233,15.679,20.3,0,30.323-5.653,30.323-16.963,0-8.481-6.432-13.881-17.735-13.881H-286.7c-31.868,0-50.619-16.182-50.619-42.911,0-34.177,28.518-54.734,77.088-54.734,22.606,0,40.6,5.139,53.962,15.677,13.62,10.539,20.3,24.159,20.3,41.375l-36.356-.854c-4.139-.1-7.359-3.131-8.465-7.116-2.927-10.5-13.173-15.67-30.729-15.67-20.3,0-30.323,5.651-30.323,16.946,0,8.481,6.424,13.888,17.727,13.888h41.121c31.86,0,50.619,16.182,50.619,42.911,0,34.177-28.526,54.994-77.088,54.994C-282.335,1295.6-300.834,1290.452-314.967,1279.921Z" transform="translate(949.652 29.525)" />
          <path id="Tracé_114" data-name="Tracé 114" d="M-317.066,1162.2v-32.893h48.822V1162.2Zm0,159.574V1166.314h48.822v155.459Z" transform="translate(1094.103 0)" />
          <path id="Tracé_115" data-name="Tracé 115" d="M-149.773,1188.441v103.808H-198.6v-95.326c0-18.769-10.278-31.1-30.063-31.1-20.565,0-32.128,13.361-32.128,31.1v95.326h-48.822v-155.46h48.822v26.468c6.172-17.734,25.7-29.81,53.457-29.81C-171.614,1133.447-149.773,1153.239-149.773,1188.441Z" transform="translate(1147.291 29.525)" />
          <path id="Tracé_116" data-name="Tracé 116" d="M-316.834,1155.725q-23.382-22.224-61.337-22.224c-25.558,0-46.261,7.408-61.865,22.224-15.58,14.832-23.249,34.259-23.249,58.531,0,24.289,7.668,43.708,23.249,58.54,15.6,14.563,36.055,21.972,61.865,21.972,22.492,0,41.4-5.612,56.482-17.125a67.282,67.282,0,0,0,26.233-42.35.427.427,0,0,0-.415-.5h-41.187a7.777,7.777,0,0,0-7.294,5.269c-5.131,14.572-17.881,23.541-33.819,23.541-18.67,0-31.437-13.043-35.283-35.283h67.834v-.024h51.156a.4.4,0,0,0,.415-.374c.236-2.829.488-7.311.488-13.661C-293.562,1189.984-301.246,1170.557-316.834,1155.725Zm-96.1,41.414c4.342-21.215,16.613-32.966,34.763-32.966,19.174,0,31.941,12.271,36.283,32.966Z" transform="translate(51.326 29.91)" />
          <path id="Tracé_117" data-name="Tracé 117" d="M-264.277,1136.789v22.874q-16.967-26.224-50.879-26.216c-19.272,0-34.7,6.675-46.773,19.792-12.076,13.1-17.987,30.063-17.987,51.383,0,21.078,5.911,38.041,17.735,51.141q18.113,19.272,47.025,19.271c22.354,0,39.316-8.481,50.879-25.7v25.786a12.457,12.457,0,0,1-12.458,12.45h-86.65v38.779h100.434a47.494,47.494,0,0,0,47.5-47.489V1136.789Zm-33.665,106.9c-20.3,0-33.144-15.157-33.144-39.065,0-23.89,12.848-39.819,33.144-39.819s33.665,16.181,33.665,39.819C-264.277,1228.53-277.646,1243.687-297.942,1243.687Z" transform="translate(645.874 29.525)" />
        </g>
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1468 283.917" class="--path anim-t-top"  v-show="lang == 'en'" >
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_57" data-name="Rectangle 57" width="1468" height="283.917" fill="#0072ce"/>
          </clipPath>
        </defs>
        <g id="Groupe_97" data-name="Groupe 97" transform="translate(0 0)">
          <g id="Groupe_97-2" data-name="Groupe 97" transform="translate(0 0)" clip-path="url(#clip-path)">
            <path id="Tracé_248" data-name="Tracé 248" d="M210.5,109.683V238.13H150.093V120.177c0-23.22-12.717-38.473-37.195-38.473-25.434,0-39.74,16.53-39.74,38.473V238.13H12.752V0H73.157V78.847c7.626-21.943,32.1-37.205,65.174-37.205,45.787,0,72.167,23.844,72.167,68.042" transform="translate(115.544 0.003)" fill="#0072ce"/>
            <path id="Tracé_249" data-name="Tracé 249" d="M280.049,109.683V238.13H219.644V120.177c0-23.22-12.717-38.473-37.195-38.473-25.434,0-39.74,16.53-39.74,38.473V238.13H82.3V0h60.405V78.847c7.626-21.943,32.1-37.205,65.174-37.205,45.787,0,72.166,23.844,72.166,68.042" transform="translate(745.736 0.003)" fill="#0072ce"/>
            <path id="Tracé_250" data-name="Tracé 250" d="M131.872,177.408c-19.4-18.442-28.935-42.6-28.935-72.81s9.538-54.359,28.935-72.8S177.327,4.14,209.451,4.14c32.1,0,58.172,9.216,77.569,27.657s29.247,42.6,29.247,72.8-9.86,54.369-29.247,72.81c-19.4,18.12-45.153,27.345-77.569,27.345-32.124,0-58.182-9.226-77.579-27.345m123.99-72.81c0-37.195-18.442-61.673-46.411-61.673-27.667,0-46.1,24.478-46.1,61.673s18.431,61.371,46.1,61.371c27.969,0,46.411-24.166,46.411-61.371" transform="translate(932.698 37.515)" fill="#0072ce"/>
            <path id="Tracé_251" data-name="Tracé 251" d="M312.382,31.485c14.618,18.11,21.933,42.286,21.933,72.8s-7.314,55-21.933,73.132c-14.628,18.11-35.293,27.335-61.683,27.335-28.935,0-50.546-12.717-65.174-38.151v79.8H125.119V8.275h60.405v33.7C200.465,16.857,222.086,4.14,250.7,4.14c26.39,0,47.055,9.216,61.683,27.345m-38.473,72.8c0-37.195-16.852-61.361-43.554-61.361-27.657,0-44.831,24.166-44.831,61.361,0,37.839,17.174,61.683,44.831,61.683,26.7,0,43.554-23.844,43.554-61.683" transform="translate(1133.686 37.515)" fill="#0072ce"/>
            <path id="Tracé_252" data-name="Tracé 252" d="M60.164,41.73V.048H0V237.2H60.164V87.909A3.912,3.912,0,0,1,64.078,84h53.091V45.644H64.078a3.912,3.912,0,0,1-3.914-3.914" transform="translate(0 0.438)" fill="#0072ce"/>
            <path id="Tracé_253" data-name="Tracé 253" d="M95.39,122.083c4.769,27.657,20.665,43.885,43.875,43.885,19.77,0,35.626-11.077,42.044-29.116a14.58,14.58,0,0,1,13.612-9.99h47.99c-3.5,23.522-14.628,42.276-33.392,56.592s-42.276,21.3-70.255,21.3c-32.114,0-57.548-9.226-76.945-27.345-19.387-18.442-28.935-42.6-28.935-72.81S42.933,50.239,62.32,31.8,107.473,4.14,139.265,4.14q47.221,0,76.3,27.657c19.4,18.442,28.935,42.6,28.935,72.8,0,8.27-.312,13.995-.634,17.486ZM96.024,83.3h88.385c-5.4-25.756-21.3-41.008-45.143-41.008-22.577,0-37.839,14.628-43.242,41.008" transform="translate(302.497 37.515)" fill="#0072ce"/>
            <path id="Tracé_254" data-name="Tracé 254" d="M89.862,185.365q-25.746-19.558-25.756-51.5l58.826,1.268c0,18.753,12.405,27.979,37.2,27.979,25.112,0,37.517-6.992,37.517-20.987,0-10.493-7.948-17.174-21.943-17.174H124.844c-39.428,0-62.639-20.021-62.639-53.091,0-42.276,35.293-67.72,95.377-67.72,27.979,0,50.244,6.358,66.764,19.4,16.852,13.039,25.122,29.891,25.122,51.19L195.1,73.448c0-18.753-13.039-27.969-39.107-27.969-25.112,0-37.507,6.992-37.507,20.977,0,10.493,7.948,17.174,21.923,17.174h50.878c39.418,0,62.639,20.021,62.639,53.091,0,42.286-35.294,68.042-95.387,68.042-28.291,0-51.19-6.369-68.675-19.4" transform="translate(563.631 37.506)" fill="#0072ce"/>
          </g>
        </g>
      </svg>
    </div>


    <div class="the-store__infos d-flex flex-wrap --color">
      <div class="col-12 col-md-6 d-flex flex-wrap align-items-start">
        <div class="col-12 col-md-4">
          <p class="stadt">{{ content.where }}</p>
          <p>
            21 rue de tervaete<br>
            1040 Bruxelles
          </p>
          <a href="https://goo.gl/maps/fMeXVgX4MXRfJssJ6" target="_blank">{{ content.linkLabel }}</a>
        </div>
        <div class="col-12 col-md-8 d-flex flex-wrap">
          <p class="w-100 stadt">{{content.when }}</p>
          <div  class="me-5">
            <p>
              <span class="stadt">10 - 19h</span><br>
              <span v-html="content.days1"></span>
            </p>
          </div>
          <div class="me-5">
            <p>
              <span class="stadt">10 - 18h</span><br>
              <span v-html="content.days2"></span>
            </p>
          </div>
          <div>
            <p>
              <span v-html="content.days3"></span>
            </p>
          </div>

        </div>
      </div>
      <div class="col-12 col-md-6">
        <p class="txt-36 light"  v-html="content.text"></p>
      </div>
    </div>
    <div class="the-store__categories" style="color:red">
      <ul class="d-flex align-items-stretch anim-children-opacity">
        <li v-for="cat in cats" :key="cat" class="col">
          <div  v-if="cat != ''">
            <div class="text stadt">
              <h3>{{ cat }}</h3>
            </div>
            <img :src="getLink(cat)" />
          </div>
          <div  v-if="cat == ''" class="picto d-flex align-items-center justify-content-center">
            <div class="text stadt">
              <h3> </h3>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 47.25 47.25">
              <path id="Tracé_155" data-name="Tracé 155" d="M2341.1,2403.815H2322.92a5.016,5.016,0,0,1-5.01-5.012v-18.083c-.1,0-.2,0-.308,0s-.232,0-.348,0v18.084a5.012,5.012,0,0,1-5,5.01h-18.15c0,.134-.005.267-.005.4,0,.085,0,.169,0,.253h18.154a5.009,5.009,0,0,1,5,5.011v18.231c.116,0,.232,0,.348,0s.205,0,.308,0v-18.232a5.01,5.01,0,0,1,5-5.011H2341.1c0-.085,0-.168,0-.253C2341.1,2404.083,2341.1,2403.949,2341.1,2403.815Z" transform="translate(-2293.977 -2380.592)" fill="#fff" stroke="#fff" stroke-miterlimit="10" stroke-width="0.25"/>
            </svg>

          </div>
        </li>
      </ul>

    </div>
  </div>

</template>


<script>



export default {
  name:'TheStore',
  data: function() {
    return {

    }
  },
  props:{
    content:String,
    cats : String,
    lang : String
  },

  methods: {
    getLink(cat){
      var url = require(`../assets/img/cats/cat_${cat}.jpg`)
      return url
    }


  }


};
</script>

