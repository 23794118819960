<template>
  <section class="menu d-flex justify-content-between align-items-stretch">
    <LanguageSwitcher/>
  </section>

  <section class="top-anim">
   <TopAnim :sentence="$t('homeTop,catchphrase')"></TopAnim>
  </section>

  <section class="bg-black">
    <TopSlider></TopSlider>
  </section>

  <section class=" d-flex bg-black">
    <TheStore :content="$t('store')" :cats="$c()" :lang="aLang"></TheStore>
  </section>

  <section class="d-flex bg-black">
    <Services :content="$t('services')"></Services>
  </section>

  <section class="d-flex bg-black">
    <Brands :content="$t('brands')" :lang="aLang"></Brands>
  </section>

  <section class="d-flex bg-black">
    <Insta :content="$t('insta')" ></Insta>
  </section>

  <section class="d-flex bg-black">
    <Newsletter :content="$t('newsletter')"></Newsletter>
  </section>

  <footer class="bg-black">
    <div class="d-flex justify-content-between">
      <div>
        <ul>
          <li>
            <a href="https://www.instagram.com/thebikeclub.be/" target="_blank">Instagram</a>
          </li>
          <li>
            <a href="https://www.facebook.com/The-Bike-Club-111282591357673" target="_blank">Facebook</a>
          </li>

        </ul>
      </div>
      <div>
        <p>
          info@thebikeclub.be
        </p>
        <p>
          21 rue de Tervaete<br>
          1040 Bruxelles
        </p>
        <p>+32 471 70 10 87</p>
      </div>
    </div>
    <div class="d-flex justify-content-between">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" width="236.117" height="54.76" viewBox="0 0 236.117 54.76">
          <g id="Groupe_86" data-name="Groupe 86" transform="translate(-126.671 -356.464)">
            <path id="Tracé_79" data-name="Tracé 79" d="M224.544,448.881H234.6V421.948a5.1,5.1,0,0,0-5.1-5.1h-4.957Z" transform="translate(-62.173 -38.357)" fill="#fff"/>
            <rect id="Rectangle_8" data-name="Rectangle 8" width="10.062" height="39.661" transform="translate(282.254 370.863)" fill="#fff"/>
            <g id="Groupe_31" data-name="Groupe 31" transform="translate(126.671 370.864)">
              <path id="Tracé_80" data-name="Tracé 80" d="M151.452,419.609c0-6.3-2.805-10.271-7.254-10.271-4.608,0-7.469,3.972-7.469,10.271,0,6.2,2.861,10.222,7.469,10.222,4.448,0,7.254-4.025,7.254-10.222m6.407-12.177c2.437,3.017,3.655,7.095,3.655,12.177s-1.218,9.163-3.655,12.181-5.878,4.5-10.273,4.5a11.929,11.929,0,0,1-10.857-6.3V435.6H126.671V395.942h10.059v13.292a11.769,11.769,0,0,1,10.857-6.354c4.394,0,7.838,1.535,10.273,4.552" transform="translate(-126.671 -395.942)" fill="#fff"/>
            </g>
            <g id="Groupe_32" data-name="Groupe 32" transform="translate(327.944 370.864)">
              <path id="Tracé_81" data-name="Tracé 81" d="M703.254,419.609c0-6.3-2.806-10.271-7.254-10.271-4.608,0-7.469,3.972-7.469,10.271,0,6.2,2.861,10.222,7.469,10.222,4.448,0,7.254-4.025,7.254-10.222m6.407-12.177c2.437,3.017,3.655,7.095,3.655,12.177s-1.218,9.163-3.655,12.181-5.878,4.5-10.272,4.5a11.929,11.929,0,0,1-10.857-6.3V435.6H678.473V395.942h10.058v13.292a11.769,11.769,0,0,1,10.857-6.354c4.394,0,7.837,1.535,10.272,4.552" transform="translate(-678.473 -395.942)" fill="#fff"/>
            </g>
            <path id="Tracé_82" data-name="Tracé 82" d="M267.585,417.255a.9.9,0,0,1-1.613-.55V395.94H255.91V435.6h10.063V421.919a.884.884,0,0,1,1.582-.543l8.831,11.346,2.24,2.878h11.65L277.3,419.345l12.5-15.778H278.151l-10.565,13.688h0" transform="translate(-82.098 -25.077)" fill="#fff"/>
            <path id="Tracé_83" data-name="Tracé 83" d="M306.732,489.957" transform="translate(-114.383 -84.801)" fill="#fff"/>
            <path id="Tracé_84" data-name="Tracé 84" d="M750.419,395.921v.708h1v2.879h.727v-2.879h.986v-.708Zm5.793,0-.692,2.423H755.5l-.714-2.423h-1.115v3.574h.633v-2.7h.068l.788,2.7h.6l.775-2.7h.071v2.7h.679v-3.574Z" transform="translate(-396.232 -25.065)" fill="#fff"/>
            <g id="Groupe_33" data-name="Groupe 33" transform="translate(293.662 378.489)">
              <path id="Tracé_85" data-name="Tracé 85" d="M607.363,416.847h10.06v32.035h-10.06v-5.455c-1.272,3.656-5.3,6.142-11.015,6.142-7.359,0-11.861-4.077-11.861-11.33V416.847h10.06v19.647c0,3.865,2.118,6.406,6.2,6.406,4.237,0,6.619-2.754,6.619-6.406Z" transform="translate(-584.487 -416.847)" fill="#fff"/>
            </g>
            <path id="Tracé_86" data-name="Tracé 86" d="M480.08,432.32a2.257,2.257,0,0,0-2.225,1.919c-.738,4.718-3.548,7.662-7.53,7.662-4.61,0-7.682-4.027-7.682-10.227s3.073-10.279,7.682-10.279c3.969,0,6.774,2.963,7.522,7.667a2.258,2.258,0,0,0,2.225,1.913H488a.088.088,0,0,0,.088-.091,15.763,15.763,0,0,0-4.837-11.344c-3.233-3.072-7.577-4.61-12.929-4.61s-9.7,1.537-12.928,4.61-4.822,7.1-4.822,12.134,1.59,9.061,4.822,12.134c3.232,3.02,7.576,4.557,12.928,4.557,5.4,0,9.7-1.536,12.929-4.557a15.773,15.773,0,0,0,4.84-11.4.088.088,0,0,0-.088-.09Z" transform="translate(-207.028 -37.14)" fill="#fff"/>
            <path id="Tracé_87" data-name="Tracé 87" d="M359.172,435.946a1.61,1.61,0,0,0-1.511,1.092,7.171,7.171,0,0,1-7.007,4.876c-3.869,0-6.514-2.7-7.31-7.309H357.4V434.6H368a.084.084,0,0,0,.086-.076c.049-.587.1-1.516.1-2.831,0-5.029-1.591-9.053-4.821-12.126s-7.465-4.6-12.707-4.6c-5.3,0-9.584,1.535-12.816,4.6s-4.817,7.1-4.817,12.126,1.588,9.056,4.817,12.129c3.232,3.017,7.469,4.552,12.816,4.552a18.747,18.747,0,0,0,11.7-3.548,13.936,13.936,0,0,0,5.434-8.774.089.089,0,0,0-.086-.1Zm-8.518-14.629c3.972,0,6.617,2.542,7.517,6.83H343.453C344.353,423.751,346.893,421.317,350.654,421.317Z" transform="translate(-131.083 -37.161)" fill="#fff"/>
            <path id="Tracé_88" data-name="Tracé 88" d="M178.8,368.229a.472.472,0,0,0-.443.32,2.1,2.1,0,0,1-2.053,1.429c-1.134,0-1.908-.792-2.142-2.142h7.223a.025.025,0,0,0,.025-.023c.015-.172.03-.444.03-.829a4.667,4.667,0,0,0-1.412-3.553,5.183,5.183,0,0,0-3.723-1.349,5.237,5.237,0,0,0-3.755,1.349,5.178,5.178,0,0,0,0,7.107,5.268,5.268,0,0,0,3.755,1.334,5.492,5.492,0,0,0,3.429-1.04,4.084,4.084,0,0,0,1.592-2.571.026.026,0,0,0-.026-.031Zm-2.5-4.286a2.207,2.207,0,0,1,2.2,2H174.2C174.459,364.656,175.2,363.943,176.306,363.943Z" transform="translate(-28.248 -3.568)" fill="#fff"/>
            <path id="Tracé_89" data-name="Tracé 89" d="M224.544,402.221H229.5a5.1,5.1,0,0,0,5.1-5.1v-1.177h-10.06Z" transform="translate(-62.173 -25.078)" fill="#fff"/>
            <path id="Tracé_90" data-name="Tracé 90" d="M153.2,361.823V368.1h-2.952v-5.763a1.692,1.692,0,0,0-1.818-1.88,1.794,1.794,0,0,0-1.942,1.88V368.1H143.54V356.464h2.952v3.853a3.157,3.157,0,0,1,3.185-1.818C151.913,358.5,153.2,359.664,153.2,361.823Z" transform="translate(-10.716 0)" fill="#fff"/>
            <path id="Tracé_91" data-name="Tracé 91" d="M126.671,356.464h2.952v2.045a.192.192,0,0,0,.192.192h2.605v1.882h-2.605a.192.192,0,0,0-.192.192V368.1h-2.952Z" transform="translate(0 0)" fill="#fff"/>
          </g>
        </svg>
      </div>
      <div>
        <p>
          2021 / All right reserved<br>
          Website by <a href="https://www.mcarnolds.be/" target="_blank">McArnolds</a>
        </p>
      </div>
    </div>


  </footer>

  <div class="rainbow">
    <span v-for="i in 5" :key="i"></span>
    <div></div>
  </div>



</template>


<script setup>

  import { useStore } from 'vuex';
  import { computed } from 'vue';

  import LanguageSwitcher from './components/LanguageSwitcher.vue';
  import TopAnim from './components/TopAnim.vue';
  import TopSlider from './components/TopSlider.vue';
  import TheStore from './components/TheStore.vue';
  import Services from './components/Services.vue';
  import Brands from './components/Brands.vue';
  import Insta from './components/Insta.vue';
  import Newsletter from './components/Newsletter.vue';

  import TRANSLATIONS from './translations.json';

  import {gsap} from "gsap/all";
  import { ScrollTrigger } from "gsap/ScrollTrigger";
  gsap.registerPlugin(ScrollTrigger);

  const store = useStore();
  const activeLanguage = computed(() => store.getters.activeLanguage);

  let aLang = activeLanguage;

  const activeLanguageFromBrowser = window.location.pathname.split('/')[1];
  if (activeLanguageFromBrowser) {
    store.dispatch('setLanguage', activeLanguageFromBrowser);
  }

  const $t = (string) => {
    let array = string.split(",");
    var content = TRANSLATIONS[activeLanguage.value][''+array[0]+''];
    for (var i = 1; i < array.length; i++) {
      content = content[''+array[1]+''];
    }
    return content;
  }

  const $c = () => {
    var categories =  ["road","mtb","","cargo","city"];
    return categories;
  }

  document.addEventListener('DOMContentLoaded', function () {

    var iColors = 0;
    var duration = 1;
    var delay = 5;
    var colors = ['#0072CE','#FC4C02','#FEDD00','#009639'];
    document.documentElement.style.setProperty('--bg', colors[iColors]);
    changeColor();
    function changeColor(){

      if(iColors <  (colors.length - 1)){
        iColors ++;
      }else{
        iColors = 0;
      }

      gsap.to("html",  {'--bg':colors[iColors], duration:duration,delay:delay,onComplete:function(){
          changeColor();
        }
      });
    }


    var tl2;

    gsap.utils.toArray(".anim-t-top").forEach(box => {
      tl2 = gsap.timeline({
        scrollTrigger: {
          trigger: box,
          toggleActions: "play none play none",
          start: "top 85%"
        }
      });
      tl2.to(box, {
        duration: 1,
        y:0,
        alpha:1
      });
    });

    gsap.utils.toArray(".anim-children-opacity").forEach(box => {
      var d = 0;
      var items = box.childNodes;
      items.forEach(item => {
        tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: box,
            toggleActions: "play none play none",
            start: "top 90%"
          }
        });
        tl2.to(item, {
          duration: 1,
          alpha:1,
          delay: d
        });

        d = d+0.1;
      });
    });
  });

</script>

