<template>
  <div class="newsletter w-100">
    <div class="newsletter__wrapper d-flex --border">
      <div class="newsletter__left col-12 col-md-6  --bg">
        <p class="txt-36 light">{{ content.text }}</p>
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <!--<p class="d-flex align-items-center">

              <label for="email">
                <input type="text" name="email" id="email" :placeholder="content.placeholder">
              </label>
              <button>
                <svg class=" me-3" xmlns="http://www.w3.org/2000/svg" width="46.306" height="21.752" viewBox="0 0 46.306 21.752">
                  <g id="Groupe_73" data-name="Groupe 73" transform="translate(-2130.13 2981.042)">
                    <path id="Tracé_92" data-name="Tracé 92" d="M2456.555-2959.319l1.337.029a10.233,10.233,0,0,1,10.222-10.22l0-1.336a10.223,10.223,0,0,1-10.219-10.2l-1.337,0Z" transform="translate(-291.678 0)" fill="#ffffff"/>
                    <path id="Tracé_93" data-name="Tracé 93" d="M2167.329-2885.272h-37.19c0,.236-.009.473-.009.71,0,.209.005.418.008.628h37.191" transform="translate(0 -85.575)" fill="#ffffff"/>
                  </g>
                </svg>
              </button>
            </p>-->
            <div id="mc_embed_signup">
              <form action="https://thebikeclub.us20.list-manage.com/subscribe/post?u=c785ecea294cf90433b047aa5&amp;id=89d0240e25" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                <div id="mc_embed_signup_scroll" class="d-flex align-items-center">

                  <div class="mc-field-group">
                    <label for="mce-EMAIL"> <input type="email" autocomplete="off" value="" name="EMAIL" class="required email" id="mce-EMAIL" :placeholder="content.placeholder">
                    </label>
                  </div>
                  <div id="mce-responses" class="clear foot">
                    <div class="response" id="mce-error-response" style="display:none"></div>
                    <div class="response" id="mce-success-response" style="display:none"></div>
                  </div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
                  <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_c785ecea294cf90433b047aa5_89d0240e25" tabindex="-1" value=""></div>
                  <div class="optionalParent">
                    <div class="clear foot">
                      <button type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">
                        <svg class=" me-3" xmlns="http://www.w3.org/2000/svg" width="46.306" height="21.752" viewBox="0 0 46.306 21.752">
                          <g id="Groupe_73" data-name="Groupe 73" transform="translate(-2130.13 2981.042)">
                            <path id="Tracé_92" data-name="Tracé 92" d="M2456.555-2959.319l1.337.029a10.233,10.233,0,0,1,10.222-10.22l0-1.336a10.223,10.223,0,0,1-10.219-10.2l-1.337,0Z" transform="translate(-291.678 0)" fill="#ffffff"/>
                            <path id="Tracé_93" data-name="Tracé 93" d="M2167.329-2885.272h-37.19c0,.236-.009.473-.009.71,0,.209.005.418.008.628h37.191" transform="translate(0 -85.575)" fill="#ffffff"/>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="108.484" height="55.208" viewBox="0 0 108.484 55.208">
              <g id="Groupe_71" data-name="Groupe 71" transform="translate(125.429 -4565.906)">
                <g id="Groupe_69" data-name="Groupe 69" transform="translate(-74.296 4565.906)">
                  <path id="Tracé_176" data-name="Tracé 176" d="M418.073,4594.236l-6.261-14.767h0l-.226-.528-.092-.218h0l-5.483-12.816H384.924v2.5h19.434l4.9,11.453a21.915,21.915,0,0,0-9.23,27.111l15.57-6.6A4.687,4.687,0,0,0,418.073,4594.236Z" transform="translate(-384.924 -4565.906)" fill="#fff"/>
                  <path id="Tracé_177" data-name="Tracé 177" d="M668.47,4694.692l15.595-6.612a21.91,21.91,0,0,0-28.315-11.387l6.578,15.513A4.691,4.691,0,0,0,668.47,4694.692Z" transform="translate(-628.614 -4664.117)" fill="#fff"/>
                  <path id="Tracé_178" data-name="Tracé 178" d="M775.04,4810.7l-15.608,6.618a4.685,4.685,0,0,0-2.472,6.137l6.632,15.642a21.91,21.91,0,0,0,11.448-28.4Z" transform="translate(-719.35 -4786.176)" fill="#fff"/>
                  <path id="Tracé_179" data-name="Tracé 179" d="M553.622,4912.234l-15.574,6.6a21.91,21.91,0,0,0,28.336,11.528l-6.632-15.641A4.685,4.685,0,0,0,553.622,4912.234Z" transform="translate(-522.706 -4877.202)" fill="#fff"/>
                </g>
                <path id="Tracé_180" data-name="Tracé 180" d="M-94.535,4716.583a19.4,19.4,0,0,1-8.864,2.13,19.548,19.548,0,0,1-19.526-19.525,19.548,19.548,0,0,1,19.526-19.526,19.548,19.548,0,0,1,19.526,19.526,19.414,19.414,0,0,1-1.648,7.847h2.72a22.024,22.024,0,0,0,1.433-7.847,21.887,21.887,0,0,0-6.452-15.578,21.886,21.886,0,0,0-15.578-6.453,21.886,21.886,0,0,0-15.578,6.453,21.887,21.887,0,0,0-6.453,15.578,21.889,21.889,0,0,0,6.453,15.578,21.887,21.887,0,0,0,15.578,6.452,21.978,21.978,0,0,0,8.864-1.85Z" transform="translate(0 -100.105)" fill="#fff"/>
                <g id="Groupe_70" data-name="Groupe 70" transform="translate(-103.398 4599.083)">
                  <path id="Tracé_181" data-name="Tracé 181" d="M94.453,4919.07h2.5a19.548,19.548,0,0,1,19.526-19.525v-2.5a22.031,22.031,0,0,0-22.031,22.03Z" transform="translate(-94.453 -4897.04)" fill="#0072ce"/>
                  <path id="Tracé_182" data-name="Tracé 182" d="M119.18,4941.321h2.5a17.068,17.068,0,0,1,17.048-17.048v-2.5A19.575,19.575,0,0,0,119.18,4941.321Z" transform="translate(-116.703 -4919.291)" fill="#fc4c02"/>
                  <path id="Tracé_183" data-name="Tracé 183" d="M143.656,4963.344h2.5a14.613,14.613,0,0,1,14.6-14.6v-2.5A17.121,17.121,0,0,0,143.656,4963.344Z" transform="translate(-138.726 -4941.313)"/>
                  <path id="Tracé_184" data-name="Tracé 184" d="M168.591,4985.78h2.5a12.112,12.112,0,0,1,12.1-12.1v-2.5A14.619,14.619,0,0,0,168.591,4985.78Z" transform="translate(-161.163 -4963.75)" fill="#fedd00"/>
                  <path id="Tracé_185" data-name="Tracé 185" d="M193.554,5008.242h2.5a9.607,9.607,0,0,1,9.6-9.6v-2.5A12.115,12.115,0,0,0,193.554,5008.242Z" transform="translate(-183.625 -4986.212)" fill="#009639"/>
                </g>
              </g>
            </svg>
          </div>

        </div>
      </div>
      <div class="newsletter__right  col-12 col-md-6">
        <div class="img" :style="getBg('newsletter')">

        </div>
      </div>
    </div>
  </div>
</template>


<script>


export default {
  name:'Newsletter',
  data: function() {
    return {

    }
  },
  props:{
    content:String
  },

  methods: {
    getBg(img){
      var url = 'background-image:url('+require(`../assets/img/${img}.jpg`)+')'
      return url
    },
    getList(list){

      const services = list.split(',');
      return services;
    }
  }
};
</script>

