<template>
  <div class="instagram w-100">
    <div class="instagram__wrapper d-flex flex-wrap align-items-stretch">
      <div class="instagram__left d-flex align-items-end justify-content-center">
        <a href="https://www.instagram.com/thebikeclub.be/" target="_blank" class="rotate txt-36 stadt">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  viewBox="0 0 24.08 183.181" class="--path">
          <defs>
            <clipPath id="clip-path">
              <path id="Tracé_197" data-name="Tracé 197" d="M0,15.279H22.366V-7.084H0Z" transform="translate(0 7.084)" />
            </clipPath>
          </defs>
          <g id="Groupe_270" data-name="Groupe 270" transform="translate(-172.236 -5467)">
            <g id="Groupe_72" data-name="Groupe 72" transform="translate(2801.743 5044.205) rotate(-90)">
              <path id="Tracé_186" data-name="Tracé 186" d="M-561.14-2625.462a1.266,1.266,0,0,0-1.453,1.422v2.275h3.508v4.108h-3.508v11.819h-5.814v-11.819h-2.94v-4.108h2.94v-2.307c0-3.634,1.8-5.435,5.435-5.435h3.887v4.045Z" />
              <path id="Tracé_187" data-name="Tracé 187" d="M-555.451-2608.145a9.5,9.5,0,0,1-2.876-7.237,9.5,9.5,0,0,1,2.876-7.237,10.76,10.76,0,0,1,7.711-2.749,10.761,10.761,0,0,1,7.71,2.749,9.525,9.525,0,0,1,2.907,7.237,9.522,9.522,0,0,1-2.907,7.237,10.832,10.832,0,0,1-7.71,2.718A10.883,10.883,0,0,1-555.451-2608.145Zm12.324-7.237c0-3.7-1.833-6.13-4.613-6.13-2.75,0-4.582,2.433-4.582,6.13s1.832,6.1,4.582,6.1C-544.96-2609.283-543.127-2611.684-543.127-2615.382Z" />
              <path id="Tracé_188" data-name="Tracé 188" d="M-536.145-2605.838v-23.669h6v23.669Z" />
              <path id="Tracé_189" data-name="Tracé 189" d="M-528.845-2605.838v-23.669h6v23.669Z" />
              <path id="Tracé_190" data-name="Tracé 190" d="M-518.985-2608.145a9.5,9.5,0,0,1-2.876-7.237,9.5,9.5,0,0,1,2.876-7.237,10.759,10.759,0,0,1,7.711-2.749,10.761,10.761,0,0,1,7.71,2.749,9.525,9.525,0,0,1,2.907,7.237,9.522,9.522,0,0,1-2.907,7.237,10.832,10.832,0,0,1-7.71,2.718A10.882,10.882,0,0,1-518.985-2608.145Zm12.324-7.237c0-3.7-1.833-6.13-4.613-6.13-2.75,0-4.582,2.433-4.582,6.13s1.832,6.1,4.582,6.1C-508.494-2609.283-506.661-2611.684-506.661-2615.382Z" />
              <path id="Tracé_191" data-name="Tracé 191" d="M-476.609-2624.956h5.909l-3.982,19.118h-8.658l-2.5-12.925-2.5,12.925h-8.659l-3.982-19.118h5.91l2.528,13.966,2.623-13.966h8.152l2.623,13.966Z" />
              <path id="Tracé_192" data-name="Tracé 192" d="M-448.9-2624.956h6v19.118h-6v-3.255c-.758,2.181-3.16,3.666-6.573,3.666-4.392,0-7.078-2.434-7.078-6.763v-12.766h6v11.723c0,2.307,1.265,3.823,3.7,3.823a3.649,3.649,0,0,0,3.95-3.823Z" />
              <path id="Tracé_193" data-name="Tracé 193" d="M-439.1-2607.355a6.122,6.122,0,0,1-2.559-5.119l4.918.106a1.113,1.113,0,0,1,1.031.873c.343,1.291,1.541,1.928,3.6,1.928,2.5,0,3.728-.7,3.728-2.086,0-1.043-.79-1.707-2.18-1.707h-5.056c-3.919,0-6.226-1.99-6.226-5.277,0-4.2,3.508-6.731,9.481-6.731a10.424,10.424,0,0,1,6.636,1.928,6.092,6.092,0,0,1,2.5,5.088l-4.471-.1a1.132,1.132,0,0,1-1.042-.875c-.36-1.291-1.619-1.927-3.778-1.927-2.5,0-3.729.7-3.729,2.085,0,1.043.79,1.707,2.18,1.707h5.056c3.918,0,6.226,1.99,6.226,5.277,0,4.2-3.508,6.763-9.48,6.763A11.141,11.141,0,0,1-439.1-2607.355Z" />
            </g>
            <g id="Groupe_78" data-name="Groupe 78" transform="translate(173.093 5634.902)">
              <g id="Groupe_77" data-name="Groupe 77" transform="translate(0 -7.084)" clip-path="url(#clip-path)">
                <g id="Groupe_74" data-name="Groupe 74" transform="translate(0.002 -0.001)">
                  <path id="Tracé_194" data-name="Tracé 194" d="M10.573,0H1.989A6.89,6.89,0,0,0-4.9,6.891v8.583a6.889,6.889,0,0,0,6.891,6.888h8.583a6.889,6.889,0,0,0,6.891-6.888V6.891A6.89,6.89,0,0,0,10.573,0m4.745,14.651a5.567,5.567,0,0,1-5.569,5.565H2.813a5.567,5.567,0,0,1-5.572-5.565V7.715A5.57,5.57,0,0,1,2.813,2.147H9.749a5.569,5.569,0,0,1,5.569,5.569Z" transform="translate(4.902)" />
                </g>
                <g id="Groupe_75" data-name="Groupe 75" transform="translate(5.59 5.33)">
                  <path id="Tracé_195" data-name="Tracé 195" d="M4.027,0A5.891,5.891,0,1,0,9.917,5.891,5.891,5.891,0,0,0,4.027,0m0,9.432A3.543,3.543,0,1,1,7.572,5.891,3.541,3.541,0,0,1,4.027,9.432" transform="translate(1.867)" />
                </g>
                <g id="Groupe_76" data-name="Groupe 76" transform="translate(15.47 3.738)">
                  <path id="Tracé_196" data-name="Tracé 196" d="M2.047,1.024a1.5,1.5,0,1,1-1.5-1.5,1.5,1.5,0,0,1,1.5,1.5" transform="translate(0.949 0.475)" />
                </g>
              </g>
            </g>
          </g>
        </svg>

        </a>
        <div class="bottom">
          <svg class="--path me-3" xmlns="http://www.w3.org/2000/svg" width="46.306" height="21.752" viewBox="0 0 46.306 21.752">
            <g id="Groupe_73" data-name="Groupe 73" transform="translate(-2130.13 2981.042)">
              <path id="Tracé_92" data-name="Tracé 92" d="M2456.555-2959.319l1.337.029a10.233,10.233,0,0,1,10.222-10.22l0-1.336a10.223,10.223,0,0,1-10.219-10.2l-1.337,0Z" transform="translate(-291.678 0)" />
              <path id="Tracé_93" data-name="Tracé 93" d="M2167.329-2885.272h-37.19c0,.236-.009.473-.009.71,0,.209.005.418.008.628h37.191" transform="translate(0 -85.575)" />
            </g>
          </svg>
          <svg xmlns="http://www.w3.org/2000/svg" width="125.639" height="52.022" viewBox="0 0 125.639 52.022">
            <g id="Groupe_84" data-name="Groupe 84" transform="translate(641.432 -285.592)">
              <path id="Tracé_198" data-name="Tracé 198" d="M-569.108,347.258h5.353V332.927a2.715,2.715,0,0,0-2.715-2.715h-2.638Z" transform="translate(-53.328 -32.9)" fill="#fff"/>
              <rect id="Rectangle_28" data-name="Rectangle 28" width="5.354" height="21.104" transform="translate(-558.645 293.254)" fill="#fff"/>
              <g id="Groupe_79" data-name="Groupe 79" transform="translate(-641.432 293.254)">
                <path id="Tracé_199" data-name="Tracé 199" d="M-628.246,327.359c0-3.352-1.493-5.465-3.86-5.465-2.452,0-3.974,2.114-3.974,5.465,0,3.3,1.522,5.439,3.974,5.439,2.367,0,3.86-2.141,3.86-5.439m3.409-6.48a10.032,10.032,0,0,1,1.945,6.48,10.038,10.038,0,0,1-1.945,6.482,6.63,6.63,0,0,1-5.466,2.395,6.348,6.348,0,0,1-5.777-3.352v2.986h-5.352v-21.1h5.352v7.073a6.263,6.263,0,0,1,5.777-3.381,6.65,6.65,0,0,1,5.466,2.422" transform="translate(641.432 -314.765)" fill="#fff"/>
              </g>
              <g id="Groupe_80" data-name="Groupe 80" transform="translate(-534.333 293.254)">
                <path id="Tracé_200" data-name="Tracé 200" d="M-220.485,327.359c0-3.352-1.493-5.465-3.86-5.465-2.452,0-3.974,2.114-3.974,5.465,0,3.3,1.522,5.439,3.974,5.439,2.367,0,3.86-2.141,3.86-5.439m3.409-6.48a10.031,10.031,0,0,1,1.945,6.48,10.036,10.036,0,0,1-1.945,6.482,6.63,6.63,0,0,1-5.466,2.395,6.348,6.348,0,0,1-5.777-3.352v2.986h-5.352v-21.1h5.352v7.073a6.262,6.262,0,0,1,5.777-3.381,6.65,6.65,0,0,1,5.466,2.422" transform="translate(233.671 -314.765)" fill="#fff"/>
              </g>
              <path id="Tracé_201" data-name="Tracé 201" d="M-539.717,326.106a.479.479,0,0,1-.858-.293V314.764h-5.354v21.1h5.354v-7.28a.47.47,0,0,1,.842-.289l4.7,6.038,1.192,1.531h6.2l-6.9-8.65,6.649-8.4h-6.2l-5.622,7.283h0" transform="translate(-70.418 -21.51)" fill="#fff"/>
              <path id="Tracé_202" data-name="Tracé 202" d="M-508.374,384.239" transform="translate(-98.11 -72.737)" fill="#fff"/>
              <path id="Tracé_203" data-name="Tracé 203" d="M-180.505,314.749v.377h.533v1.532h.387v-1.532h.524v-.377Zm3.083,0-.368,1.29h-.009l-.38-1.29h-.593v1.9h.337v-1.439h.036l.419,1.439h.32l.413-1.439h.038v1.439h.361v-1.9Z" transform="translate(-339.864 -21.499)" fill="#fff"/>
              <g id="Groupe_81" data-name="Groupe 81" transform="translate(-552.575 297.312)">
                <path id="Tracé_204" data-name="Tracé 204" d="M-290.95,330.213h5.353v17.046h-5.353v-2.9c-.677,1.945-2.818,3.268-5.861,3.268-3.916,0-6.311-2.169-6.311-6.029V330.213h5.353v10.454a3.069,3.069,0,0,0,3.3,3.409,3.254,3.254,0,0,0,3.522-3.409Z" transform="translate(303.123 -330.213)" fill="#fff"/>
              </g>
              <path id="Tracé_205" data-name="Tracé 205" d="M-385.966,338.05a1.2,1.2,0,0,0-1.184,1.021c-.392,2.511-1.888,4.077-4.007,4.077-2.453,0-4.088-2.143-4.088-5.442s1.635-5.47,4.088-5.47c2.112,0,3.6,1.577,4,4.08a1.2,1.2,0,0,0,1.184,1.018h4.221a.047.047,0,0,0,.047-.049,8.388,8.388,0,0,0-2.574-6.036,9.6,9.6,0,0,0-6.88-2.453,9.6,9.6,0,0,0-6.879,2.453,8.475,8.475,0,0,0-2.566,6.456,8.474,8.474,0,0,0,2.566,6.456,9.708,9.708,0,0,0,6.879,2.425,9.663,9.663,0,0,0,6.88-2.425A8.394,8.394,0,0,0-381.7,338.1a.047.047,0,0,0-.047-.048Z" transform="translate(-177.576 -31.857)" fill="#fff"/>
              <path id="Tracé_206" data-name="Tracé 206" d="M-475.032,339.986a.856.856,0,0,0-.8.581,3.815,3.815,0,0,1-3.728,2.6c-2.059,0-3.466-1.437-3.89-3.889h7.478v0h5.639a.045.045,0,0,0,.046-.041c.026-.312.053-.807.053-1.506a8.473,8.473,0,0,0-2.565-6.452,9.411,9.411,0,0,0-6.762-2.45,9.51,9.51,0,0,0-6.82,2.45,8.472,8.472,0,0,0-2.563,6.452,8.473,8.473,0,0,0,2.563,6.454,9.569,9.569,0,0,0,6.82,2.422,9.977,9.977,0,0,0,6.227-1.888,7.416,7.416,0,0,0,2.892-4.669.047.047,0,0,0-.046-.055Zm-4.533-7.784c2.114,0,3.521,1.352,4,3.634H-483.4C-482.918,333.5-481.566,332.2-479.565,332.2Z" transform="translate(-112.434 -31.875)" fill="#fff"/>
              <path id="Tracé_207" data-name="Tracé 207" d="M-569.108,318.106h2.638a2.715,2.715,0,0,0,2.715-2.715v-.626h-5.353Z" transform="translate(-53.328 -21.511)" fill="#fff"/>
              <g id="Groupe_82" data-name="Groupe 82" transform="translate(-641.432 285.592)">
                <path id="Tracé_208" data-name="Tracé 208" d="M-603.842,290.461a2.758,2.758,0,0,0-1.981-.718,2.787,2.787,0,0,0-2,.718,2.483,2.483,0,0,0-.751,1.891,2.484,2.484,0,0,0,.751,1.891,2.8,2.8,0,0,0,2,.71A2.923,2.923,0,0,0-604,294.4a2.175,2.175,0,0,0,.847-1.368.014.014,0,0,0-.013-.016h-1.33a.251.251,0,0,0-.236.17,1.118,1.118,0,0,1-1.092.76c-.6,0-1.015-.421-1.14-1.139h3.843a.013.013,0,0,0,.013-.012c.008-.091.016-.236.016-.441A2.483,2.483,0,0,0-603.842,290.461Zm-3.1,1.338c.14-.685.537-1.065,1.123-1.065a1.175,1.175,0,0,1,1.172,1.065Z" transform="translate(617.203 -288.653)" fill="#fff"/>
                <path id="Tracé_209" data-name="Tracé 209" d="M-625.7,286.675a1.68,1.68,0,0,0-1.694.967v-2.05h-1.571v6.191h1.571v-3.067a.955.955,0,0,1,1.033-1,.9.9,0,0,1,.967,1v3.067h1.57v-3.339A1.651,1.651,0,0,0-625.7,286.675Z" transform="translate(632.241 -285.592)" fill="#fff"/>
                <path id="Tracé_210" data-name="Tracé 210" d="M-639.862,286.68v-1.088h-1.57v6.191h1.57v-3.9a.1.1,0,0,1,.1-.1h1.386v-1h-1.386A.1.1,0,0,1-639.862,286.68Z" transform="translate(641.432 -285.592)" fill="#fff"/>
              </g>
              <g id="Groupe_83" data-name="Groupe 83" transform="translate(-617.428 316.138)">
                <path id="Tracé_211" data-name="Tracé 211" d="M-550.039,406.739c0-3.242,1.606-4.848,4.848-4.848h3.466V405.5h-1.832a1.129,1.129,0,0,0-1.3,1.268V408.8h3.128v3.664h-3.128V423h-5.186Z" transform="translate(550.039 -401.891)" fill="#fff"/>
                <path id="Tracé_212" data-name="Tracé 212" d="M-441.073,426.4c.423,2.452,1.833,3.89,3.89,3.89a3.818,3.818,0,0,0,3.727-2.581,1.292,1.292,0,0,1,1.206-.886H-428a7.452,7.452,0,0,1-2.959,5.017,9.983,9.983,0,0,1-6.228,1.888A9.566,9.566,0,0,1-444,431.308a8.472,8.472,0,0,1-2.565-6.454A8.471,8.471,0,0,1-444,418.4a9.5,9.5,0,0,1,6.821-2.452,9.41,9.41,0,0,1,6.764,2.452,8.47,8.47,0,0,1,2.565,6.454c0,.733-.028,1.24-.057,1.55Zm.057-3.439h7.835c-.479-2.283-1.888-3.635-4-3.635C-439.185,419.33-440.538,420.627-441.017,422.965Z" transform="translate(473.746 -412.256)" fill="#fff"/>
                <path id="Tracé_213" data-name="Tracé 213" d="M-354.466,421.979v11.386h-5.354V422.909a3.069,3.069,0,0,0-3.3-3.411,3.255,3.255,0,0,0-3.523,3.411v10.456H-372V416.315h5.355v2.9c.677-1.945,2.819-3.269,5.862-3.269C-356.862,415.948-354.466,418.118-354.466,421.979Z" transform="translate(418.759 -412.256)" fill="#fff"/>
                <path id="Tracé_214" data-name="Tracé 214" d="M-288.739,401.891h5.355V423h-5.355v-2.987a6.345,6.345,0,0,1-5.777,3.354,6.63,6.63,0,0,1-5.468-2.4,10.044,10.044,0,0,1-1.945-6.482,10.043,10.043,0,0,1,1.945-6.482,6.65,6.65,0,0,1,5.468-2.424,6.26,6.26,0,0,1,5.777,3.382Zm0,12.6c0-3.353-1.522-5.467-3.973-5.467-2.367,0-3.861,2.114-3.861,5.467,0,3.3,1.494,5.44,3.861,5.44C-290.261,419.928-288.739,417.787-288.739,414.489Z" transform="translate(367.095 -401.891)" fill="#fff"/>
                <path id="Tracé_215" data-name="Tracé 215" d="M-225.335,432.013a5.46,5.46,0,0,1-2.283-4.565l5.214.112c0,1.663,1.1,2.48,3.3,2.48,2.226,0,3.325-.62,3.325-1.86,0-.93-.7-1.522-1.945-1.522h-4.509c-3.5,0-5.552-1.775-5.552-4.707,0-3.748,3.129-6,8.455-6a9.3,9.3,0,0,1,5.918,1.719,5.432,5.432,0,0,1,2.227,4.538l-4.82-.113c0-1.663-1.156-2.48-3.466-2.48-2.226,0-3.325.62-3.325,1.86,0,.93.7,1.522,1.944,1.522h4.51c3.494,0,5.552,1.775,5.552,4.707,0,3.749-3.128,6.031-8.455,6.031A9.933,9.933,0,0,1-225.335,432.013Z" transform="translate(312.427 -412.256)" fill="#fff"/>
                <path id="Tracé_216" data-name="Tracé 216" d="M-513.437,417.474v16.982h5.333v-12.23a.5.5,0,0,1,.5-.5h4.555v-4.253Z" transform="translate(523.051 -413.381)" fill="#fff"/>
                <path id="Tracé_217" data-name="Tracé 217" d="M-470.278,404.795v-2.9h5.354v1.273a1.631,1.631,0,0,1-1.631,1.631Zm0,18.205V405.95h3.723a1.631,1.631,0,0,1,1.631,1.631V423Z" transform="translate(491.227 -401.891)" fill="#fff"/>
              </g>
            </g>
          </svg>
        </div>
      </div>
      <div class="instagram__right  --border">
        <div class="row " id="instafeed"></div>
      </div>
    </div>

  </div>
</template>


<script>

import Instafeed from "@/assets/js/insta";

export default {
  name:'Services',
  data: function() {
    return {

    }
  },

  mounted() {
    var feed = new Instafeed({
      accessToken: 'IGQVJVeENfUzZAqemN2ZAnNNUFpCVXBUVXd3bEw5SkFrdVlVQmI0NmpJMzhCVFZADczlkTlpROTdNUkVsX3VvaGRidmJqZATlkOFlWMlpMM09WUVpPQ1BvT1B1LXdaYWxUSzdGdk5GT1J2d0VuNGRRRGo5RwZDZD',
      limit:4,
      template:'<div class="col-6 col-md-3" ><a href="{{link}}" target="_blank" ><img src="{{image}}" class="w-100  item-bordered-rounded --border" title="{{caption}}"></a>  </div>'
    });
    feed.run();
  },
  props:{
    content:String
  },



};
</script>

