import { createStore } from 'vuex';

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
let lang = urlParams.get('l');
if (!lang){
   lang = 'fr'
}

export default createStore({
  state: {
    activeLanguage: lang,
  },
  getters:{
    activeLanguage(state) {
      return state.activeLanguage;
    },
  },
  mutations: {
    SET_LANGUAGE(state, language) {
      state.activeLanguage = language;
    },
  },
  actions: {
    setLanguage(context, language) {
      context.commit('SET_LANGUAGE', language);
    },
  },

});

