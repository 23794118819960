<template>
  <div class="services">
    <div class="services__wrapper d-flex flex-wrap --border">
      <div class="services__left col-12 col-md-6">
        <div class="img" :style="getBg('services')">

        </div>
        <img >
      </div>
      <div class="services__right  col-12 col-md-6 --bg">
        <div class="top d-flex flex-wrap">
          <h3 class="stadt col-12 col-lg-4">{{ content.label }}</h3>
          <div class=" col-12 col-md-8 txt-36 light">
            <p>{{ content.description }}</p>
          </div>
        </div>
        <div class="bottom d-flex flex-wrap">
          <ul class="d-flex align-items-start flex-wrap w-100 anim-children-opacity">
            <li v-for="service in getList(content.servicesList)"  :key="service">
              <h3>{{ service }}</h3>
            </li>
          </ul>
          <div class="w-100">
            <a href="mailto:info@thebikeclub.be">
              <svg xmlns="http://www.w3.org/2000/svg" width="46.306" height="21.752" viewBox="0 0 46.306 21.752" class="me-2">
                <g id="Groupe_68" data-name="Groupe 68" transform="translate(-2130.13 2981.042)">
                  <path id="Tracé_92" data-name="Tracé 92" d="M2456.555-2959.319l1.337.029a10.233,10.233,0,0,1,10.222-10.22l0-1.336a10.223,10.223,0,0,1-10.219-10.2l-1.337,0Z" transform="translate(-291.678 0)" fill="#fff"/>
                  <path id="Tracé_93" data-name="Tracé 93" d="M2167.329-2885.272h-37.19c0,.236-.009.473-.009.71,0,.209.005.418.008.628h37.191" transform="translate(0 -85.575)" fill="#fff"/>
                </g>
              </svg>
              {{ content.appointment }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>


export default {
  name:'Services',
  data: function() {
    return {

    }
  },
  props:{
    content:String
  },

  methods: {
    getBg(img){
      var url = 'background-image:url('+require(`../assets/img/${img}.jpg`)+')'
      return url
    },
    getList(list){

      const services = list.split(',');
      return services;
    }
  }
};
</script>

