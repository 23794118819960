<template>
  <div class="top-anim__marquee">
    <div class="top-anim__wrapper d-flex">
      <div class="top-anim__item"  v-for="index in 6" :key="index">
        <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 432.356 432.367">
          <g id="Groupe_147" data-name="Groupe 147" transform="translate(-953.684 -79.892)">
            <path id="Tracé_315" data-name="Tracé 315" d="M169.983,213.162a43.287,43.287,0,0,0,43.171-43.273V0A216.3,216.3,0,0,0,0,213.162Z" transform="translate(953.684 79.893)" fill="#fff"/>
            <path id="Tracé_316" data-name="Tracé 316" d="M647.109,0V169.877a43.329,43.329,0,0,0,43.276,43.286H860.272A216.324,216.324,0,0,0,647.109,0" transform="translate(525.388 79.893)" fill="#fff"/>
            <path id="Tracé_317" data-name="Tracé 317" d="M690.309,647.143a43.287,43.287,0,0,0-43.171,43.273V860.306A216.3,216.3,0,0,0,860.291,647.143Z" transform="translate(525.369 -348.428)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="0.75"/>
            <path id="Tracé_318" data-name="Tracé 318" d="M213.163,860.307V690.43a43.329,43.329,0,0,0-43.276-43.286H0A216.324,216.324,0,0,0,213.163,860.307" transform="translate(953.684 -348.428)" fill="#fff"/>
          </g>
        </svg>


      </div>

    </div>
  </div>
  <h1 style="font-size: 0; margin-bottom: 0">The Bike Club</h1>
  <div class="top-title d-flex flex-wrap align-items-end">
    <div class="top-title__left col-12 col-md-10">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1468 340.458">
        <g id="Groupe_34" data-name="Groupe 34" transform="translate(-126.671 -356.464)">
          <path id="Tracé_79" data-name="Tracé 79" d="M224.544,616.019h62.545V448.568a31.723,31.723,0,0,0-31.724-31.722H224.544Z" transform="translate(124.082 76.552)" fill="#fff"/>
          <rect id="Rectangle_8" data-name="Rectangle 8" width="62.558" height="246.583" transform="translate(1093.974 445.988)" fill="#fff"/>
          <g id="Groupe_31" data-name="Groupe 31" transform="translate(126.671 445.992)">
            <path id="Tracé_80" data-name="Tracé 80" d="M280.742,543.09c0-39.163-17.441-63.859-45.1-63.859-28.649,0-46.437,24.7-46.437,63.859,0,38.53,17.789,63.553,46.437,63.553,27.656,0,45.1-25.023,45.1-63.553m39.836-75.71c15.153,18.759,22.723,44.108,22.723,75.71s-7.57,56.971-22.723,75.733S284.031,646.8,256.71,646.8c-29.629,0-52.021-13.169-67.5-39.165v34.89H126.671V395.942h62.536v82.64c15.158-26.338,37.546-39.507,67.5-39.507,27.32,0,48.728,9.545,63.868,28.3" transform="translate(-126.671 -395.942)" fill="#fff"/>
          </g>
          <g id="Groupe_32" data-name="Groupe 32" transform="translate(1378.041 445.992)">
            <path id="Tracé_81" data-name="Tracé 81" d="M832.544,543.09c0-39.163-17.444-63.859-45.1-63.859-28.651,0-46.437,24.7-46.437,63.859,0,38.53,17.786,63.553,46.437,63.553,27.653,0,45.1-25.023,45.1-63.553m39.833-75.71c15.154,18.759,22.725,44.108,22.725,75.71s-7.572,56.971-22.725,75.733S835.833,646.8,808.512,646.8c-29.631,0-52.023-13.169-67.5-39.165v34.89H678.473V395.942h62.537v82.64c15.158-26.338,37.545-39.507,67.5-39.507,27.32,0,48.726,9.545,63.865,28.3" transform="translate(-678.473 -395.942)" fill="#fff"/>
          </g>
          <path id="Tracé_82" data-name="Tracé 82" d="M328.5,528.458a5.6,5.6,0,0,1-10.028-3.422V395.94H255.91V642.523h62.562V557.459a5.5,5.5,0,0,1,9.833-3.375l54.905,70.544,13.929,17.9H469.57L388.918,541.453l77.694-98.1H394.186l-65.684,85.1h0" transform="translate(163.848 50.047)" fill="#fff"/>
          <path id="Tracé_83" data-name="Tracé 83" d="M306.732,489.957" transform="translate(228.279 169.241)" fill="#fff"/>
          <path id="Tracé_84" data-name="Tracé 84" d="M750.419,395.921v4.4h6.227v17.9h4.52v-17.9h6.127v-4.4Zm36.019,0-4.3,15.067h-.1l-4.44-15.067h-6.93v22.218H774.6V401.325h.425l4.9,16.813h3.737l4.821-16.813h.44v16.813h4.22V395.921Z" transform="translate(790.78 50.023)" fill="#fff"/>
          <g id="Groupe_33" data-name="Groupe 33" transform="translate(1164.9 493.4)">
            <path id="Tracé_85" data-name="Tracé 85" d="M726.713,416.847h62.545V616.02H726.713V582.107c-7.906,22.728-32.926,38.187-68.485,38.187-45.755,0-73.742-25.345-73.742-70.442V416.847h62.545V538.995c0,24.027,13.169,39.829,38.53,39.829,26.343,0,41.152-17.12,41.152-39.829Z" transform="translate(-584.487 -416.847)" fill="#fff"/>
          </g>
          <path id="Tracé_86" data-name="Tracé 86" d="M623.58,523.049a14.035,14.035,0,0,0-13.836,11.929c-4.586,29.336-22.061,47.635-46.814,47.635-28.66,0-47.764-25.039-47.764-63.582s19.1-63.909,47.764-63.909c24.673,0,42.117,18.423,46.768,47.667a14.036,14.036,0,0,0,13.831,11.892h49.313a.548.548,0,0,0,.544-.565q-1.555-43.46-30.073-70.526c-20.1-19.1-47.111-28.66-80.384-28.66s-60.285,9.559-80.377,28.66-29.978,44.147-29.978,75.44,9.883,56.332,29.978,75.44c20.092,18.777,47.1,28.329,80.377,28.329,33.6,0,60.284-9.552,80.384-28.329q28.629-27.227,30.091-70.859a.548.548,0,0,0-.544-.562Z" transform="translate(413.177 74.123)" fill="#fff"/>
          <path id="Tracé_87" data-name="Tracé 87" d="M495.61,545.42a10.007,10.007,0,0,0-9.4,6.792c-6.613,18.766-23.034,30.318-43.562,30.318-24.054,0-40.5-16.8-45.446-45.442h87.373v-.036H550.47a.521.521,0,0,0,.535-.474c.306-3.647.624-9.425.624-17.6,0-31.266-9.894-56.286-29.971-75.39q-30.112-28.625-79.005-28.624c-32.924,0-59.586,9.541-79.683,28.624-20.077,19.1-29.948,44.124-29.948,75.39s9.872,56.3,29.948,75.406c20.1,18.759,46.438,28.3,79.683,28.3,28.967,0,53.323-7.234,72.753-22.061,18.321-13.972,29.533-32.105,33.785-54.552a.551.551,0,0,0-.537-.651Zm-52.957-90.954c24.694,0,41.142,15.8,46.737,42.464H397.877C403.472,469.6,419.269,454.466,442.653,454.466Z" transform="translate(261.608 74.164)" fill="#fff"/>
          <path id="Tracé_88" data-name="Tracé 88" d="M218.778,400.3a2.933,2.933,0,0,0-2.753,1.989,13.063,13.063,0,0,1-12.763,8.885c-7.048,0-11.865-4.924-13.316-13.316h25.6v-.009h19.306a.154.154,0,0,0,.156-.141c.091-1.068.184-2.76.184-5.155,0-9.162-2.9-16.494-8.781-22.091s-13.6-8.386-23.149-8.386c-9.647,0-17.457,2.8-23.347,8.386s-8.776,12.929-8.776,22.091,2.894,16.5,8.776,22.093c5.89,5.5,13.6,8.291,23.347,8.291,8.488,0,15.623-2.118,21.317-6.463a25.393,25.393,0,0,0,9.9-15.983.163.163,0,0,0-.159-.191Zm-15.516-26.649c7.234,0,12.055,4.629,13.693,12.441H190.143C191.783,378.089,196.411,373.656,203.262,373.656Z" transform="translate(56.376 7.121)" fill="#fff"/>
          <path id="Tracé_89" data-name="Tracé 89" d="M224.544,434.98h30.821a31.723,31.723,0,0,0,31.724-31.722v-7.316H224.544Z" transform="translate(124.082 50.05)" fill="#fff"/>
          <path id="Tracé_90" data-name="Tracé 90" d="M203.614,389.785V428.8H185.263V392.971c0-7.051-3.862-11.686-11.3-11.686-7.726,0-12.071,5.023-12.071,11.686V428.8H143.54v-72.34h18.351v23.952c2.318-6.665,9.754-11.3,19.8-11.3C195.6,369.116,203.614,376.359,203.614,389.785Z" transform="translate(21.386 0)" fill="#fff"/>
          <path id="Tracé_91" data-name="Tracé 91" d="M126.671,356.464h18.351v12.716a1.194,1.194,0,0,0,1.193,1.193h16.2v11.7h-16.2a1.193,1.193,0,0,0-1.193,1.193V428.8H126.671Z" transform="translate(0 0)" fill="#fff"/>
        </g>
      </svg>


    </div>
    <div class="top-title__right col-12 col-md-1">
      <p v-html="sentence"></p>
    </div>
  </div>
</template>



<script>

import {gsap} from "gsap/all";



export default {

  name:'TopAnim',
  props:{
    sentence:String
  },

  mounted() {

    // Animation loaded

    var d = 0;
    setTimeout(function(){
      gsap.utils.toArray(".rainbow span").forEach(box => {
        var tl = gsap.timeline();
        tl.to(box, {height:'5px', duration:0.5, delay:d});
        d += 0.2;
        gsap.to('.rainbow div',{alpha:0, delay:1, onComplete:function(){
            document.getElementsByTagName('body')[0].style.overflow = "auto";
            gsap.to('.menu',{y: 0, duration:1});
            gsap.to('.top-title svg', {alpha:1, duration:1.5})
            gsap.to('.top-anim__wrapper',{y: 0, duration:1,onComplete:function(){
                const vit = 4;
                gsap.to('.top-anim__wrapper',{x:0, duration:vit, ease:"linear", repeat:-1})
                gsap.utils.toArray(".top-anim__item svg").forEach(box => {
                  var tl = gsap.timeline();
                  tl.to(box, {rotate:'+='+450, duration:vit, ease:"linear", repeat:-1});
                });
              }});
          }})
      });
    },500)





  }

}

</script>

<style>

</style>
