<template>
  <div class="top-slider">
    <div class="swiper swiper-top">
      <!-- Additional required wrapper -->
      <div class="swiper-wrapper">
        <!-- Slides -->
        <div class="swiper-slide" v-for="index in 5" :key="index"><img :src="getLink(index)"  class="--border"></div>
      </div>

    </div>
    <div class="swiper-next d-flex align-items-center justify-content-end"  v-on:click="nextSlider">
      <div class="count --color">
        <span id="currentSlide">1</span>/<span>5</span>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" width="54.292" height="25.502" viewBox="0 0 54.292 25.502" class="--path">
        <g id="Groupe_46" data-name="Groupe 46" transform="translate(-2130.13 2981.042)">
          <path id="Tracé_92" data-name="Tracé 92" d="M2456.555-2955.573l1.567.034a12,12,0,0,1,11.985-11.982l0-1.567a11.986,11.986,0,0,1-11.981-11.953l-1.567.006Z" transform="translate(-285.686 0)" />
          <path id="Tracé_93" data-name="Tracé 93" d="M2173.744-2885.272h-43.6c0,.277-.01.554-.01.833,0,.246.006.491.009.737h43.6" transform="translate(0 -83.817)"/>
        </g>
      </svg>

    </div>
  </div>

</template>


<script>
import { Swiper } from 'swiper';
import 'swiper/swiper.scss';

export default {
  mounted() {
    this.swiper = new Swiper('.swiper-top',{
      slidesPerView: "auto",
      spaceBetween: 0,
      loop:true
    });

    let a = this.swiper;
    this.swiper.on('slideChangeTransitionEnd', function(){
      let i = a.realIndex;
      document.getElementById("currentSlide").innerHTML = (i+1);
    })
  },
  methods: {
    nextSlider: function () {
      this.swiper.slideNext();
    },
    getLink(index){
      var url = require(`../assets/img/slider0${index}.jpg`)
      return url
    }
  }
};
</script>

